/* AIAssistant.module.css */

/* Root container */
.container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 360px;
  max-width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #ebebeb;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6d00cc;
  padding: 16px 20px;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.closeButton {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.closeButton:hover {
  opacity: 0.8;
}

/* Messages area */
.messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #f6f6f6;
}

/* Scrollbar styling */
.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #f6f6f6;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* Message container */
.messageContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}

/* User messages aligned to the right */
.userMessageContainer {
  justify-content: flex-end;
}

/* Assistant messages aligned to the left */
.assistantMessageContainer {
  justify-content: flex-start;
}

/* Avatar styling */
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #FEC200;
}

/* Message bubbles */
.userMessage {
  max-width: 60%;
  background-color: #6d00cc;
  color: #fff;
  padding: 12px 16px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.assistantMessage {
  max-width: 60%;
  background-color: #e2e2e2;
  color: #333;
  padding: 12px 16px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Typing indicator */
.typingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
  color: #333;
  border-radius: 20px;
  padding: 12px 16px;
  max-width: 60%;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Animated dots */
.dot {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  background-color: #6d00cc;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* Input area */
.inputArea {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #ebebeb;
  padding: 12px 16px;
}

.inputField {
  flex: 1;
  font-size: 14px;
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 24px;
  resize: none;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  max-height: 100px;
  margin-right: 8px;
}

.inputField:focus {
  border-color: #6d00cc;
  box-shadow: 0 0 0 2px rgba(109, 0, 204, 0.1);
}

.sendButton {
  background-color: #6d00cc;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendButton:hover {
  background-color: #8e2de2;
  transform: translateY(-2px);
}

.sendButton:active {
  transform: translateY(0);
}

.sendButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .container {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .inputArea {
    padding: 12px;
  }

  .messages {
    padding: 12px;
  }

  .messageContainer {
    margin-bottom: 8px;
  }

  .avatar {
    width: 32px;
    height: 32px;
  }

  .userMessage,
  .assistantMessage,
  .typingIndicator {
    font-size: 13px;
    padding: 10px 14px;
    max-width: 70%;
  }
}


.micButton {
  background-color: #6d00cc;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  margin-right: 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.micButton:hover {
  background-color: #8e2de2;
  transform: translateY(-2px);
}

.micButton:active {
  transform: translateY(0);
}

/* If you need an active style (e.g., blinking or color change while listening) */
.micButton.listening {
  background-color: red; /* or some other indicator color */
}