/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Use Poppins font */
}

.modalIconContainer {
  margin-bottom: 20px;
}

.modalIcon {
  background-color: #F6F6F6; /* Light gray circle */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.deleteTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}

.deleteMessage {
  font-size: 1rem;
  color: #555;
  margin-bottom: 25px;
}

.confirmDeleteStrong {
  font-weight: bold;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.confirmBtn {
  background-color: #FF4C4C; /* Darker red */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelBtn {
  background-color: #F1F1F1;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
}

.confirmBtn:hover {
  background-color: #E63946; /* Slightly darker red on hover */
}

.cancelBtn:hover {
  background-color: #DDD;
}

@media screen and (max-width: 768px) {
  .modal {
    max-width: 90%;
  }
}
