/* Container */
.settingsContainer {
  display: flex;
  background-color: #f9fafb;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  position: relative;
}

/* SIDEBAR */
.sidebar {
  position: fixed;
  left: 0;
  width: 220px; /* or 280px if you want a larger sidebar */
  overflow-y: auto;
  background-color: #fff;
  border-right: 1px solid #e1e4e8;
  z-index: 999;
  padding-top: 50px;
  transition: transform 0.4s ease, top 0.4s ease, height 0.4s ease;

}

/* 
  These two classes are toggled via showOriginalNavbar 
  to adjust the 'top' and 'height' for the sidebar
*/
.sidebarLarge {
  top: 107px;
  height: calc(100vh - 70px);
}
.sidebarCompact {
  top: 47px;
  height: calc(100vh - 20px);
}

/* SIDEBAR NAV LIST */
.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Larger nav items */
.navItem {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Increase padding/font-size to appear more "large" */
  padding: 14px 24px;
  font-size: 17px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.navItem:hover {
  background-color: #f5f5f5;
}

.active {
  background-color: #eef4ff;
  color: #6d00cc; 
  border-left: 3px solid #6d00cc;
}

/* MAIN PANEL */
.mainPanel {
  /* shift content to the right of the sidebar */
  margin-left: 220px; /* match .sidebar width */
  margin-top: 10px;   /* same as .sidebarLarge top if your navbar is visible */
  padding: 30px 50px;
}



/* FIXED WIDTH FOR SETTINGS CONTENT */
.fixedWidthContainer {
  width: 1000px; /* or 1000px, etc. */
  margin: 0 auto;   /* center horizontally */
}

/* CONTENT CONTAINER */
.contentContainer {
  background-color: #fff;
  padding: 20px 30px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
}

/* SECTION TITLE */
.sectionTitle {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

/* FORM GROUPS */
.inputGroup {
  margin-bottom: 25px;
}

.inputLabel {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #444;
}

.charLimit {
  font-size: 0.85rem;
  color: #888;
  margin-left: 6px;
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
}

.inputField:focus {
  border-color: #0066ff;
}

.textArea {
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  resize: none; 
  padding: 10px;
  outline: none;
}

/* Limit to 3 lines max for short description */
.shortDescTextarea {
  line-height: 1.5;
  height: calc(1.5em * 3 + 2rem);
  max-height: calc(1.5em * 3 + 2rem);
  overflow-y: auto; 
}

.textArea:focus {
  border-color: #0066ff;
}

/* Character count inside the box (bottom-right) */
.positionRelative {
  position: relative;
}

.charCountInside {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.85rem;
  color: #999;
}

.charCount {
  text-align: right;
  color: #999;
  font-size: 0.85rem;
  margin-top: 4px;
}

/* Rich Text Editor */
.richTextEditor {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}

/* Hints */
.fieldHint {
  display: block;
  margin-top: 5px;
  font-size: 0.85rem;
  color: #777;
}

/* IMAGE UPLOAD */
.imageUploadBox {
  position: relative;
  width: 100%;
  min-height: 160px;
  border: 2px dashed #ddd;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.imageUploadBox:hover {
  background-color: #fafafa;
}

.hiddenFileInput {
  display: none;
}

.uploadPlaceholder {
  margin-top: 30px;
  color: #aaa;
  font-size: 14px;
}

.uploadPlaceholder i {
  font-size: 24px;
  margin-bottom: 8px;
  display: block;
  color: #ccc;
}

.formatInfo {
  font-size: 12px;
  color: #ccc;
}

.uploadedImage {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  }

/* Toggle Switch */
.toggleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; 
  width: 18px;
  left: 3px; 
  bottom: 3px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #6d00cc;
}

.switch input:checked + .slider:before {
  transform: translateX(22px);
}

.toggleLabel {
  font-size: 14px;
  color: #555;
}

/* VISIBILITY CHOICES */
.visibilityChoice {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.visibilityOption {
  flex: 1 1 180px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
  transition: 0.2s;
}

.optionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-bottom: 5px;
}

.visibilityOption:hover {
  background-color: #f5f8fa;
}

.selected {
  border-color: #69c772; 
  background-color: #eaffea;
}

/* INTEGRATIONS (Google Sheets/Drive) */
.integrationOption {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  gap: 16px; 
  background-color: #fff;
}

.integrationImageWrapper {
  flex-shrink: 0;
}

.integrationImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
  display: block;
}

.integrationText {
  flex: 1;
}

.integrationOption h4 {
  margin-top: 0;
  margin-bottom: 6px;
}

.connectButton {
  background-color: #6d00cc;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.connectButton:hover {
  background-color: #5900a6;
}

.dragActive {
  background-color: #f0f8ff; /* a light blue highlight */
  border: 2px dashed #6d00cc;
}