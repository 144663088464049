:root {
    --brand-color: #2A4A7B;
    --accent-color: #2A4A7B;
    --success-color: #28a745;
    --error-color: #d9534f;
    --bg-light: #f9f9f9;
    --text-dark: #333;
    --text-light: #f1f1f1;
}

.pageWrapper {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background: linear-gradient(to bottom right, #fdfdfd, #f9f9f9);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--text-dark);
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dark {
    background: #2a2a2a;
    color: var(--text-light);
}

.toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.backButton, .toggleModeButton {
    background: none;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s;
}

.backButton:hover, .toggleModeButton:hover {
    background: var(--accent-color);
    color: #fff;
}

.notification {
    background: var(--success-color);
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.95rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    animation: fadeInDown 0.3s ease;
    z-index: 100;
}

@keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

.helpToggle {
    background: none;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s;
    margin-bottom: 10px;
}

.helpToggle:hover {
    background: var(--accent-color);
    color: #fff;
}

.helpPanel {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    animation: fadeIn 0.3s ease;
    font-size: 0.95rem;
    color: #333;
}

.dark .helpPanel {
    background: #444;
    color: #f1f1f1;
    border-color: #555;
}

.helpPanel h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.helpPanel ul {
    padding-left: 20px;
    list-style: disc;
    margin: 0;
}

.headerSection {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
}

.title {
    color: var(--accent-color);
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dark .title {
    color: #bbdefb;
}

.description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
}

.dark .description {
    color: #ddd;
}

.overallProgressContainer {
    max-width: 500px;
    margin: 0 auto 30px;
    text-align: left;
    position: relative;
}

.overallProgressHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.overallProgressLabel {
    font-size: 0.95rem;
    color: #333;
}

.dark .overallProgressLabel {
    color: #eee;
}

.overallProgressBarBackground {
    background: #e0e0e0;
    border-radius: 10px;
    height: 14px;
    overflow: hidden;
    position: relative;
}

.overallProgressBarFill {
    background: var(--accent-color);
    height: 100%;
    transition: width 0.5s ease;
    position: relative;
    overflow: hidden;
}

.overallProgressBarFill::after {
    content: "";
    position: absolute;
    top:0; left:0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, rgba(255,255,255,0.3), rgba(255,255,255,0));
    animation: shine 2s infinite;
}

@keyframes shine {
    0% { left: -50px; }
    100% { left: 100%; }
}

.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipIcon {
    background: var(--accent-color);
    color: #fff;
    border-radius: 50%;
    padding: 0 6px;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    line-height: 1.5;
    position: relative;
    z-index: 10;
}

.tooltipText {
    visibility: hidden;
    background: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9rem;
    white-space: nowrap;
    z-index: 11;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipContainer:hover .tooltipText,
.tooltipContainer:focus-within .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tasksSection {
    margin-top: 20px;
    animation: fadeIn 0.6s ease forwards;
    opacity: 0;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.loader {
    width: 40px;
    height: 40px;
    border: 5px solid #eee;
    border-top: 5px solid var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    100% { transform: rotate(360deg); }
}

.loadingText {
    color: #555;
    font-size: 1rem;
}

.dark .loadingText {
    color: #bbb;
}
