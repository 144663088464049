.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modalHeader {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 15px;
}

.headerContent h2 {
  margin: 0;
  font-size: 1.5rem;
}

.versionCount {
  background: #f0f0f0;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #666;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modalBody {
  padding: 20px;
}

.versionTimeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.versionCard {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
}

.latestVersion {
  border-color: #4CAF50;
  background: #f8fff8;
}

.versionHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.versionInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.versionNumber {
  font-weight: bold;
  font-size: 1.1rem;
}

.latestBadge {
  background: #4CAF50;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.dateInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  font-size: 0.9rem;
}

.versionStats {
  display: flex;
  gap: 15px;
  color: #666;
  font-size: 0.9rem;
}

.versionStats span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.versionActions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background: white;
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.actionButton:hover {
  background: #f5f5f5;
}

.deleteButton {
  composes: actionButton;
  color: #dc3545;
  border-color: #dc3545;
}

.deleteButton:hover {
  background: #fff5f5;
}

.parentVersion {
  border-left: 4px solid #2196F3;
  background-color: #f8faff;
}

.parentBadge {
  background: #2196F3;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 8px;
}

.parentNote {
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 8px;
}
