:root {
    --accent-color: #2A4A7B;
    --success-color: #28a745;
    --error-color: #d9534f;
    --info-color: #17a2b8;
    --text-dark: #333;
    --text-medium: #555;
    --text-light: #f1f1f1;
    --bg-light: #fff;
    --bg-dark: #3a3a3a;
    --font-primary: 'Inter', sans-serif;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: var(--font-primary);
}

.header {
    text-align: center;
    padding: 0 20px;
}

.title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--accent-color);
}

.subtitle {
    font-size: 1rem;
    color: var(--text-medium);
}

.tasksContainer {
    background: var(--bg-light);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    position: relative;
}

.dark .tasksContainer {
    background: var(--bg-dark);
    color: var(--text-light);
}

.searchBarContainer {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.searchWrapper {
    position: relative;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 10px;
    width: 250px;
}

.searchIcon {
    color: #999;
    margin-right: 8px;
    font-size: 1rem;
}

.searchInput {
    border: none;
    background: transparent;
    width: 100%;
    font-size: 0.9rem;
    color: #333;
    outline: none;
    font-family: var(--font-primary);
}

.searchInput::placeholder {
    color: #aaa;
}

.clearFilterButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #999;
    font-size: 1rem;
    margin-left: 8px;
}

.clearFilterButton:hover {
    color: var(--accent-color);
}

.taskItem {
    display: flex;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.1s ease;
    position: relative;
    outline: none;
    border: 1px solid #eee;
}

.dark .taskItem {
    background: #444;
    border-color: #555;
}

.taskItem:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
}

.leftBorder {
    width: 6px;
    border-radius: 8px 0 0 8px;
    background: var(--accent-color);
}

.taskDone .leftBorder {
    background: var(--success-color);
}

.taskContent {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.taskHeaderLeft {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.taskHeaderRight {
    display: flex;
    align-items: center;
    gap: 10px;
}

.stepNumber {
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--text-medium);
}

.taskName {
    font-weight: 600;
    font-size: 1rem;
    color: var(--accent-color);
    position: relative;
    transition: color 0.3s;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}

.taskName:hover {
    color: var(--accent-color);
    text-decoration: underline;
}

.taskMeta {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    font-size: 0.9rem;
    color: var(--text-medium);
}

/* Colored dots for statuses */
.dotGrey {
    background-color: #6c757d;
    /* Grey */
}

.dotBlue {
    background-color: #007BFF;
    /* Blue */
}

.dotGreen {
    background-color: #28a745;
    /* Green */
}

.dotRed {
    background-color: #d9534f;
    /* Red */
}

.statusLabel {
    line-height: 1;
    margin-left: 4px;
}

.assigneeContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.assigneeIcon {
    font-size: 1rem;
    color: var(--text-medium);
}

.assigneeName {
    font-size: 0.9rem;
    color: var(--text-dark);
}

.dark .assigneeName {
    color: var(--text-light);
}

.dueDate {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.taskStatus {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--text-dark);
}

.taskStatusPill {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 9999px;
    padding: 6px 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background 0.3s;
}

.taskStatusPill:hover {
    background: #f5f5f5;
}

.statusDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}

/* Newly Added Status Styles */
.notStarted {
    background-color: #6c757d;
    /* Grey color for Not Started */
    color: #fff;
}

.inProgress {
    background-color: #007BFF;
    /* Blue color for In Progress */
    color: #fff;
}

.completed {
    background-color: #28a745;
    /* Green color for Completed */
    color: #fff;
}

/* Progress Area */
.progressArea {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.progressContainer {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    height: 25px;
}

.progressBar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.noResults {
    text-align: center;
    font-size: 1rem;
    color: #555;
    margin-top: 20px;
}

.dark .noResults {
    color: #ccc;
}

.resetButton {
    background: none;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    transition: background 0.3s;
}

.resetButton:hover {
    background: var(--accent-color);
    color: #fff;
}