/* Toolbar.module.css */

/*****************************************************************
 * ROOT CONTAINER
 *****************************************************************/
 .toolbarContainer {
  position: fixed;
  left: 0;
  width: 300px; /* A bit wider for a more comfortable design */
  height: calc(100vh - 60px);
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 9998;

  /* Smooth transitions for both vertical offset & horizontal open/close. */
  transition:
    top 0.45s cubic-bezier(0.65, 0, 0.35, 1),
    transform 0.45s cubic-bezier(0.65, 0, 0.35, 1);
}

/* Drawer fully visible */
.open {
  transform: translateX(0);
}

/* Drawer hidden except for a small edge. Adjust for personal taste. */
.closed {
  transform: translateX(-280px);
  margin-right: 25px;
}

/*****************************************************************
 * SEARCH BAR
 *****************************************************************/
.searchWrapper {
  padding: 10px;
  background: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
}

.searchBox {
  position: relative;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px 40px 8px 12px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.searchBox:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
}

.searchIcon {
  position: absolute;
  right: 12px;
  font-size: 14px;
  color: #999;
}

.searchBox input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #333;
}

/*****************************************************************
 * TABS
 *****************************************************************/
.tabsRow {
  display: flex;
  background-color: #fafafa;
  justify-content: space-evenly;
  border-bottom: 1px solid #e0e0e0;
  padding: 6px 0;
}

.tabButton {
  position: relative;
  background: transparent;
  border: none;
  font-size: 14px;
  font-family:'Poppins', sans-serif;
  font-weight: 600;
  color: #666;
  padding: 8px 10px;
  cursor: pointer;
  transition: color 0.3s;
  flex: 1;
  text-align: center;
}

.tabButton:hover {
  color: #6d00cc;
}

/* Active tab indicator */
.activeTab {
  color: #6d00cc;
}

.activeUnderline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #6d00cc;
  animation: tabLineGrow 0.3s ease-out;
}

@keyframes tabLineGrow {
  0%   { width: 0; }
  100% { width: 100%; }
}

/*****************************************************************
 * FIELDS AREA
 *****************************************************************/
.fieldsContainer {
  flex: 1;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  /* Add custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #6d00cc #f1f1f1;
  margin-bottom: 60px;

}

/* Custom scrollbar for webkit browsers */
.fieldsContainer::-webkit-scrollbar {
  width: 6px;
}

.fieldsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.fieldsContainer::-webkit-scrollbar-thumb {
  background: #6d00cc;
  border-radius: 3px;
}

.fieldsContainer::-webkit-scrollbar-thumb:hover {
  background: #5b00ad;
}

.fieldsList {
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 10px;
  /* Remove any height constraints to allow natural content flow */
  min-height: min-content;
}

/*****************************************************************
 * FIELD ITEM
 *****************************************************************/
.fieldItem {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 14px;
  display: flex;
  font-family:'Poppins', sans-serif;

  align-items: center;
  gap: 10px;
  cursor: grab;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.fieldItem:hover {
  background: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.fieldIcon {
  color: #6d00cc;
  font-size: 16px;
}

.noResults {
  margin-top: 20px;
  text-align: center;
  color: #999;
  font-style: italic;
  font-size: 14px;
}

/*****************************************************************
 * TOGGLE BUTTON
 *****************************************************************/
.toggleButton {
  position: fixed;
  width: 36px;
  height: 36px;
  background-color: #6d00cc;
  color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(109, 0, 204, 0.3);
  cursor: pointer;
  z-index: 9999;
  margin-top: 20px;

  /* Smooth transitions for top & left positions */
  transition:
    left 0.45s cubic-bezier(0.65, 0, 0.35, 1),
    top 0.45s cubic-bezier(0.65, 0, 0.35, 1),
    background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Button hover effect */
.toggleButton:hover {
  background-color: #5b00ad;
}

/* If the drawer is open, keep the button near the right edge (260px).
   If the drawer is closed, shift it so the user sees the button. */
.openBtn {
  left: 280px;
}

.closedBtn {
  left: 10px;
}


