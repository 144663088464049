/* src/styles/DashboardTitle.css */
.dashboard-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .dashboard-title-container h1 {
    color: #663399;
    font-size: 24px;
    margin: 0;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px 10px;
    width: 250px;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    color: #888;
  }
  
  .search-container input {
    padding: 8px 8px 8px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border: none;
    background: none;
    outline: none;
    font-size: 14px;
    width: 100%;
  }
  