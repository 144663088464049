.pageTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.spacer {
    padding-top: 5%;
}

.createWorkflowButton {
  margin-bottom: 20px;
  margin-left: 70%;
  background-color: #1d3557;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}


.geminiButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 30px;
}

.geminiButton img {
  width: 40px;
  height: 40px;
}

.geminiChatWindow {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100;
  align-items: center;
  
}

.geminiChatWindow h3 {
  margin-bottom: 10px;
}

.geminiChatWindow textarea {
  width: 95%;
  max-width: 95%;
  height: 80px;
  padding: 10px;
  margin-bottom: 10px;
}

.geminiChatWindow button {
  background-color: #000;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.geminiChatWindow button:first-child {
  margin-right: 10px;
}


/* A bold, gradient-styled title */
.dependencyPageTitle {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  /* Gradient text trick: */
  background: linear-gradient(45deg, #6d00cc, #b166ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}


.dependencyPageDescription {
  margin-top: 20px;
  background-color: #f8efff; /* a light purple tint, tweak as desired */
  border-radius: 6px;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  color: #7E22CE;
  font-weight: 600;
  margin-bottom: 1.0rem;
  line-height: 1.5;
  width: fit-content;
}


/* The default overlay for loading or updating 
   (this sits on top of the entire screen) */
   .loadingOverlay {
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* on top of everything */
    font-family: 'Poppins', sans-serif;
  }
  
  /* The overlay we use when AI is generating
     We want it behind the AI assistant. 
     So we give it a lower z-index. 
     Make sure your AIAssistant's z-index is 
     something above 1000, for instance. */
  .aiOverlay {
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 500; /* lower than your AIAssistant window */
    font-family: 'Poppins', sans-serif;
  }
  
  /* A basic spinner, reused for both overlays */
  .spinner {
    width: 60px;
    height: 60px;
    border: 6px solid #ccc;
    border-top-color: #6d00cc;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  