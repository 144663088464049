:root {
    --time-step: 80px; /* horizontal step size */
    --line-color: #e0e0e0;
    --row-height: 70px;
    --task-bar-height: 30px;
}

/* Container */
.visualizationContainer {
    margin-bottom: 40px;
    position: relative;
}

.visualizationTitle {
    font-size: 1.4rem;
    margin-bottom: 20px;
    color: var(--accent-color, #2A4A7B);
    text-align: center;
}

.ganttWrapper {
    position: relative;
    overflow-x: auto;
    padding: 20px;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    min-height: 200px;
}

/* Vertical lines */
.verticalLine {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: var(--line-color);
    z-index: 1;
}

/* Rows */
.ganttRow {
    position: relative;
    height: var(--row-height);
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

/* Task styles */
.ganttTask {
    position: absolute;
    height: var(--task-bar-height);
    border-radius: var(--task-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.9rem;
    gap: 5px;
    padding: 0 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    cursor: pointer;
    z-index: 2; 
    opacity: 0;     /* for fadeInUp animation */
    animation: fadeInUp 0.5s ease forwards;
}

/* Different restriction states */
.taskLocked {
    opacity: 0.7;
    background: #888; 
}

.taskViewOnly {
    opacity: 0.85;
    background: linear-gradient(45deg, #bbb, #999);
}

.taskCompleted {
    border: 2px solid #fff;
}

.taskName {
    flex-shrink: 0;
    text-overflow: ellipsis;
}

/* Icon styling */
.taskIcon {
    font-size: 1rem;
}

/* Fade In Up keyframes for tasks */
@keyframes fadeInUp {
    to {
        opacity: 1;
    }
}



/* Lines above tasks */
.arrowsOverlay {
    pointer-events: none;
    overflow: visible;
    z-index: 1;
}

.dependencyLine {
    stroke: rgba(33, 150, 243, 0.6); /* lighter version of #2196f3 */
    stroke-width: 2;
    stroke-opacity: 0.9;
    transition: stroke 0.2s ease;
    filter: drop-shadow(0 0 2px rgba(33,150,243,0.4));
}

.dependencyLine:hover {
    stroke: rgba(33, 150, 243, 0.9);
    filter: drop-shadow(0 0 4px rgba(33,150,243,0.6));
}

@keyframes drawLine {
    to {
        stroke-dashoffset: 0;
    }
}