/* Page wrapper provides a light background behind the main container */
.pageWrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f4f5f7;
    padding: 2rem 0;
}

/* ======== PROFILE HEADER SECTION (OPTIONAL) ======== */
.profileHeader {
    max-width: 900px;
    margin: 0 auto 2rem auto;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profileAvatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 1rem;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profileName {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.profileSubtitle {
    margin-top: 0.25rem;
    font-size: 1rem;
    color: #777;
}

/* ======== MAIN CONTAINER ======== */
.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 1rem;
    font-family: "Arial", sans-serif;
    color: #333;
}

.pageTitle {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
}

/* Floating 'Save All' button if sections are reordered (optional UX improvement) */
.floatingSaveAllBtn {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    background-color: #28a745;
    color: #fff;
    z-index: 999;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.floatingSaveAllBtn:hover {
    background-color: #218838;
}

.sectionBodyOpen {
    padding: 1rem 1.5rem;
}

/* ================= SECTION CARD ================== */
.sectionCard {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    margin-bottom: 1.5rem;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
}

/* Section Header with handle + arrow + title + edit/delete icons */
.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background-color: #f9f9f9;
}

.sectionHeaderLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sectionHeaderRight {
    display: flex;
    gap: 0.5rem;
}

/* Drag handle icon (decorative) next to arrow */
.dragHandleIcon {
    width: 16px;
    height: 16px;
    opacity: 0.7;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='1.5' fill='%23999'/%3E%3Ccircle cx='3' cy='7' r='1.5' fill='%23999'/%3E%3Ccircle cx='7' cy='3' r='1.5' fill='%23999'/%3E%3Ccircle cx='7' cy='7' r='1.5' fill='%23999'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    cursor: grab;
}

.dragHandleIcon:active {
    cursor: grabbing;
}

/* Arrow icon next to handle */
.arrowIcon {
    width: 16px;
    height: 16px;
    background-image: url("../../../assets/images/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s;
    cursor: pointer;
}

.arrowOpen {
    transform: rotate(180deg);
}

/* Section title */
.sectionTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
}

/* ============ FIELD ROW ============ */
.fieldRow {
    display: flex;
    align-items: center;
    background-color: #fff;
    /* changed from #f9fafb to pure white */
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    position: relative;
    cursor: grab;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.fieldRow:active {
    cursor: grabbing;
}

/* Add a hover effect on fields */
.fieldRow:hover {
    background-color: #f3f4f6;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Left handle icon (decorative) for each field row */
.fieldHandleIcon {
    width: 14px;
    height: 14px;
    margin-right: 0.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='1.5' fill='%23999'/%3E%3Ccircle cx='3' cy='7' r='1.5' fill='%23999'/%3E%3Ccircle cx='7' cy='3' r='1.5' fill='%23999'/%3E%3Ccircle cx='7' cy='7' r='1.5' fill='%23999'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
}

/* Field content */
.fieldContent {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
}

.fieldName {
    font-weight: 600;
    font-size: 1rem;
    color: #111827;
}

.fieldValue pre {
    white-space: pre-wrap;
    /* Ensures long JSON data wraps */
    word-wrap: break-word;
    /* Allows breaking of long words */
    background-color: #f8f9fa;
    /* Slightly lighter background */
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    font-size: 0.9rem;
    color: #333;
    overflow-x: auto;
    /* Ensures horizontal scroll if content overflows */
}

.fieldValue ul {
    padding-left: 1.5rem; /* Ensures indentation for the bulleted list */
    list-style-type: disc; /* Default bullet points */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .fieldValue .listItem {
    font-size: 0.95rem;
    color: #4b5563;
    margin-bottom: 0.5rem; /* Adds space between list items */
    line-height: 1.5; /* Makes the list easier to read */
  }
  
/* Right-side icons (edit/delete) */
.fieldIcons {
    display: flex;
    gap: 0.5rem;
}

/* Reusable icon button style */
.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.iconButton:hover {
    background-color: #e5e7eb;
}




/* ================ MODAL STYLES ================ */
.modalBackdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modalContainer {
    background: #fff;
    width: 420px;
    max-width: 90%;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;
}

.modalHeader h3 {
    margin: 0;
}

.closeModalBtn {
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
}

.modalBody {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.modalBody label {
    font-weight: 500;
}

.modalInput,
.modalTextarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
}

/* Example button classes for Save/Cancel inside the modal. */
.btn {
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
}

.saveBtn {
    background-color: #007bff;
    color: #fff;
}

.saveBtn:hover {
    background-color: #0056b3;
}

.cancelBtn {
    background-color: #6b7280;
    color: #fff;
}

.cancelBtn:hover {
    background-color: #4b5563;
}

.sectionHeaderClickArea {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    /* So user sees it's clickable */
}

/* A bigger, more prominent button style for "Add Section" / "Add Field" */
.bigActionBtn {
    background-color: #2563eb;
    /* a blue color */
    color: #fff;
    border: none;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 1rem;
    /* some spacing */
}

.bigActionBtn:hover {
    background-color: #1e40af;
    /* darker blue on hover */
}

/* "Show More" or "Show All" button styling */
.showMoreBtn {
    background: transparent;
    border: none;
    color: #2563eb;
    font-weight: 500;
    margin-top: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
}

.showMoreBtn:hover {
    color: #1e40af;
    text-decoration: none;
}

.loadingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.spinner {
    width: 35px;
    height: 35px;
    border: 5px solid #eee;
    border-top: 5px solid #663399;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.loadingText {
    color: #555;
    font-size: 1rem;
}