:root {
  --accent-color: #2A4A7B;
  --success-color: #28a745;
  --error-color: #dc3545;
  --info-color: #17a2b8;
  --grey-color: #6c757d;
  --blue-color: #007bff;
  --light-bg: #f9f9f9;
  --white: #fff;
  --font-family: 'Inter', sans-serif;

  /* For circle borders */
  --black-circle: #000;
  --blue-circle: #007bff;
  --green-circle: #28a745;
  --red-circle: #dc3545;
}

/* Page Wrapper */
.wrapper {
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  font-family: var(--font-family);
  padding-left: 90px;
}

.noData {
  text-align: center;
  margin-top: 2rem;
  color: var(--grey-color);
  font-size: 1rem;
}

.topBar {
  position: relative;
  /* allows absolute positioning of the back button */
  padding: 1rem 0;
  text-align: center;
  /* center text by default */
}

.userWorkflowInfo {
  display: inline-block;
  /* so it respects text-align center */

}

.userName {
  margin: 0;
  font-size: 1.8rem;
  /* bigger user name */
  font-weight: 700;
  color: #333;
}

.topInfoRow {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

/* For user info (name, workflow name) */
.userInfo {
  text-align: center;
}

.workflowName {
  margin: 0;
  font-size: 1rem;
  color: #555;
}

/* Row of circle stats */
.taskStatsRow {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem;
}

/* Each circle card container */
.circleCard {
  background-color: var(--white);
  border: 1px solid #eee;
  border-radius: 6px;
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* The circle itself */
.circle {
  width: 60px;
  height: 60px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f5;
  /* outer "ring" color */
  margin-bottom: 0.3rem;
  position: relative;
}

/* The number inside the circle */
.circleNumber {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000;
}

.circleLabel {
  font-size: 0.85rem;
  font-weight: 500;
  color: #555;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.animateLabel:hover {
  opacity: 0.8;
  transform: translateY(-2px);
  /* slight upward movement */
}

/* Different color rings */
.circleBlack {
  border: 6px solid var(--black-circle);
  background-color: transparent;
}

.circleBlue {
  border: 6px solid var(--blue-circle);
  background-color: transparent;
}

.circleGreen {
  border: 6px solid var(--green-circle);
  background-color: transparent;
}

.circleRed {
  border: 6px solid var(--red-circle);
  background-color: transparent;
}

/* Task List Container */
.taskListContainer {
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.taskCard {
  background-color: var(--white);
  border-radius: 8px;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.taskCard:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.disabledCard {
  opacity: 0.5;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1rem 0.5rem;
}

.headerInfo {
  max-width: 70%;
}

.taskTitle {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.taskDescription {
  margin: 0.25rem 0 0.75rem;
  font-size: 0.9rem;
  color: #777;
}

/* "View Details" button */
.viewDetailsButton {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  background: none;
  border: none;
  color: var(--accent-color);
  font-size: 0.85rem;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s ease;
}

.viewDetailsButton:hover {
  text-decoration: underline;
}

.headerRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}

/* Expand container in the middle section */
.expandedContainer {
  padding: 0 1rem 1rem;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fieldRow {
  display: flex;
  flex-direction: column;
}

.fieldLabel {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

.fieldInput {
  font-size: 0.85rem;
  padding: 0.45rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--light-bg);
  color: #555;
}

/* Footer with Approve / Reject always visible if "Submitted" */
.cardFooter {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.75rem 1rem 1rem;
  border-top: 1px solid #eee;
  background-color: var(--white);
}

/* Buttons (Approve/Reject) */
.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  border: 2px solid transparent;
  border-radius: 6px;
  padding: 0.45rem 1.2rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease;
}

.btnIcon {
  font-size: 0.9rem;
}

.btn:hover {
  transform: translateY(-1px);
}

/* Approve => green outline */
.btnApprove {
  border-color: #28a745;
  color: #28a745;
}

.btnApprove:hover {
  background-color: #28a745;
  color: #fff;
}

/* Reject => red outline */
.btnReject {
  border-color: #dc3545;
  color: #dc3545;
}

.btnReject:hover {
  background-color: #dc3545;
  color: #fff;
}

/* Status Pill styles (same logic you already have) */
.statusPill {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.3rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  font-weight: 500;
}

.statusIcon {
  font-size: 0.9rem;
}

.statusNotSubmitted {
  background-color: #e9eaec;
  color: #6c757d;
}

.statusSubmitted {
  background-color: #E6F2FE;
  color: #007bff;
}

.statusApproved {
  background-color: #E6F7EB;
  color: #28a745;
}

.statusRejected {
  background-color: #FDEBEB;
  color: #dc3545;
}

@keyframes labelPop {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  50% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.backBtn {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: var(--accent-color);
  color: #fff;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.25s ease, transform 0.2s ease;
}

.backBtn:hover {
  background-color: #1e3d64;
  /* slightly darker shade */
  transform: translate(-1px, -50%);
}

.rtl {
  direction: rtl;
}

.wrapper.rtl {
  padding-left: 0;
  padding-right: 90px;
}