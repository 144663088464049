/* General Container Styling */
.container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 10rem;
    margin-right: 10rem;
  }
  
  /* Loading Spinner */
  .loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  /* Card Styling */
  .card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
  
  .cardHeader {
    background: linear-gradient(135deg, #6d00cc, #b55dcd);
    color: white;
    padding: 2rem;
    text-align: center;
  }
  
  .cardTitle {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .author {
    font-size: 1rem;
    font-style: italic;
    opacity: 0.8;
  }
  
  .cardBody {
    padding: 2rem;
  }
  
  .description {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .tasksHeader {
    font-size: 1.5rem;
    color: #6d00cc;
    margin-bottom: 1.5rem;
  }
  
  .tasksContainer {
    border-top: 1px solid #eee;
  }
  
  .taskItem {
    margin-bottom: 1rem;
  }
  
  .taskHeader {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f8f8f8;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .taskHeader:hover {
    background-color: #f0f0f0;
  }
  
  .taskIcon {
    margin-right: 1rem;
    color: #6d00cc;
  }
  
  .taskName {
    font-size: 1.2rem;
    color: #333;
  }
  
  .taskContent {
    padding: 1rem 1.5rem;
    background-color: #fafafa;
    border-radius: 0 0 8px 8px;
  }
  
  .taskDescription {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  .cardFooter {
    padding: 1.5rem;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .addWorkflowButton {
    background-color: #6d00cc;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border-radius: 30px;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .addWorkflowButton:hover {
    background-color: #5900a3;
  }
  
  .noTasks {
    font-size: 1rem;
    color: #999;
    text-align: center;
    margin-top: 2rem;
  }
  
  