@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.authPageBody * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.authPageBody {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 868px;
    max-width: 100%;
    min-height: 580px;
    transition: all 0.6s ease-in-out;
    border-radius: 100px;
}


.container span {
    font-size: 12px;
}


.container button {
    border-color: #724ebf;
    background: radial-gradient(100.03% 140.18% at 0% 85.53%, #ff00ff 0%, #724ebf 95.31%);
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
    width: 330px;
    height: 38px;
}

.googleSignIn {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    display: grid;
    outline: none;
    transition: border-color 0.3s ease;
    /* align-items: center; */
    /* align-content: center; */
    justify-items: center;
    place-self: center;

}


.container input {
    background-color: #fffefe;
    border: 1px solid #ced4da;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 90%;
    outline: none;
    width: 330px;
    height: 38px;
}

input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #bdace1;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(114, 78, 191, .25);
    box-shadow: 0 0 0 .2rem rgba(114, 78, 191, .25);

}

.formContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transition: all 0.6s ease-in-out;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.formContainer .error {
    color: #ff4d4d;
    /* Ensure the color is red */
    font-size: 12px;
    /* Set a smaller font size */
    text-align: left;
    margin-bottom: 0px;
    /* Remove margin below error messages */
    margin-top: 0px;
}

.signIn {
    left: 0;
    width: 50%;
    z-index: 2;
}

.signIn input {
    width: 330px;
    height: 38px;
}

.container.active .signIn {
    transform: translateX(0%);
}

.signUp {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.signUp .error {
    margin-left: 0px;
}

.container.active .signUp {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

.toggleContainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    z-index: 1000;
    border-radius: 100px;
}

.container.active .toggleContainer {
    transform: translateX(-100%);
}

.toggle {
    background: radial-gradient(100.03% 140.18% at 0% 85.53%, #ff00ff 0%, #724ebf 68.31%);
    height: 100%;
    color: #fff;
    position: relative;
    left: -100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}


.container.active .toggle {
    transform: translateX(50%);
}

.togglePanel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggleLeft {
    transform: translateX(-200%);
}

.container.active .toggleLeft {
    transform: translateX(0);
}

.toggleRight {
    right: 0;
    transform: translateX(0);
}

.container.active .toggleRight {
    transform: translateX(200%);
}


.formContainer .error {
    color: #ff4d4d;
    /* Red color for error messages */
    font-size: 12px;
    margin-bottom: 0px;
    /* Spacing below error messages */
    text-align: left;
}

.errorList {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

/* Add this to your AuthPage.css file */

.container .errorBorder {
    border-color: #eb5768;
    /* Red border for error state */
    margin-top: 5px;
}

.nameInputs {
    display: flex;
    gap: 10px;
    /* Space between first and last name fields */
    width: 100%;
    max-width: 330px;
    /* Matches other input fields */
}



.nameInputs .inputContainer {
    flex: 1;
    /* Ensures equal width for both inputs */
}

.nameInputs .error {
    margin-left: 0;
    margin-right: 0;
}

.nameInputs input {
    flex: 1;
    /* Allow both inputs to take up equal space */
    width: 160px;
}

.singleNameError {
    margin-top: 100px;

}



.toggle button {
    background: #fff;
    color: #512da8;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.terms {
    font-size: 12px;
}

.passwordContainer {
    width: 330px;
    /* Ensure this matches the input field width */
    margin: 0 auto;
    /* Center the container */
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* Position eye icon beside the password field */
.passwordField {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}


.passwordField input {
    width: 100%;
    /* Use full width of the parent container */
    height: 38px;
    padding-right: 30px;
    /* Space for the eye icon */
}

.passwordToggleIcon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

/* Style for the reset password text */
.resetText {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
    padding: 0 20px;
    line-height: 1.5;
    text-align: center;
}

.link {
    color: #512da8;
    text-decoration: none;
    font-size: 14px;
    font-weight: semi-bold;
}

.rtl {
    direction: rtl;
}

.container.rtl.active .signIn {
    transform: translateX(0%);
    opacity: 0;
}

.container.rtl:not(.active) .signIn {
    left: 50%;
    transform: none !important;
}

.container.rtl.active .signUp {
    right: 100% !important;
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

.container.rtl:not(.active) .toggleContainer {
    right: 50% !important;
    transform: translateX(0%);
}

.container.rtl:not(.active) .toggle {
    left: auto !important;
    transform: none !important;
}

.container.rtl:not(.active) .toggleLeft {
    transform: none !important;
}

.container.rtl:not(.active) .toggleRight {
    opacity: 0 !important;
    right: auto !important;
}

/* If AR + active => user is on the register */
.container.rtl.active .toggleContainer {
    right: 0% !important;
    transform: translateX(0%);
}

.container.rtl.active .toggle {
    left: auto !important;
    transform: translateX(50%);
}

.container.rtl.active .toggleRight {
    opacity: 1 !important;
    transform: translateX(-100%);
}

.container.rtl.active .toggleLeft {
    opacity: 0 !important;
    transform: none !important;
}
