/* --------------------------------------------------
   Modal Overlay
-------------------------------------------------- */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.35);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

}

/* --------------------------------------------------
   Container sizes
-------------------------------------------------- */
.modalContainerSmall {
  background-color: #fff;
  width: 650px; /* Adjust as needed */
  height: 420px; /* Reduced height */
  border-radius: 8px;
  position: relative;
  padding: 1.5rem;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;
}

.modalContainerLarge {
  background-color: #fff;
  width: 1000px;
  height: 600px;
  border-radius: 8px;
  position: relative;
  padding: 1.5rem;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;

}

.closeButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  border: none;
  color: #444;
  font-size: 1.5rem;
  cursor: pointer;
}

/* --------------------------------------------------
   Main Title + Tip
-------------------------------------------------- */
.modalTitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.75rem;
  color: #333;
}

/* Purple tinted box for "Did you know?" style tips */
.tipBox {
  margin-top: 20px;
  background-color: #f8efff; /* a light purple tint, tweak as desired */
  border-radius: 6px;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 1.0rem;
  line-height: 1.5;
}

/* "Back" button at the bottom-left */
.backBtn {
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
  background: transparent;
  border: none;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-family: 'Poppins', sans-serif;
}

/* --------------------------------------------------
   Cards row (Create from Scratch, Template, PDF)
-------------------------------------------------- */
.cardsRow {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.card {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 200px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  background-color: #fff;
  transition: box-shadow 0.2s ease;
}

.card:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.cardSvg {
  margin-bottom: 0.5rem;
  width: 48px;
  height: 48px;
}

.cardTitle {
  font-size: 1rem;
  font-weight: 600;
  color: #222;
}

.cardDesc {
  font-size: 0.85rem;
  color: #555;
  text-align: center;
  margin-top: 50%;
}

/* --------------------------------------------------
   PDF Step
-------------------------------------------------- */
.pdfContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.pdfDropZone {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 2rem;
  text-align: center;
  width: 80%;
  cursor: pointer;
  transition: border-color 0.2s;
  color: #6d00cc;

}

.pdfDropZoneActive {
  background-color: #f7f2ff;
  border-color: #6d00cc; /* highlight in purple when dragging */
  color: #6d00cc;
}

.pdfIcon {
  font-size: 3rem;
  color: #6d00cc;
}

.pdfDropText {
  margin-top: 1rem;
  color: #555;
}

.pdfFileBox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f9f9f9;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 1rem;
  width: 80%;
  justify-content: center;
}

.uploadedFileName {
  font-size: 0.9rem;
  color: #444;
  font-weight: 600;
}

.changeFileBtn {
  background: none;
  border: none;
  color: #6d00cc;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0;
}

.pdfButtons {
  margin-top: 1.5rem;
}

.createPdfBtn {
  background-color: #ccc;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  border: none;
  cursor: not-allowed;
  font-size: 1rem;
}

.createPdfBtnActive {
  background-color: #6d00cc;
  cursor: pointer;
}

/* --------------------------------------------------
   Template Step
-------------------------------------------------- */
.templateViewContainer {
  display: flex;
  gap: 1rem;
  height: calc(90% - 60px); /* space for title + tip */
}

.sidebarContainer {
  width: 180px;
  border-right: 1px solid #eee;
  padding-right: 0.85rem;
  overflow-y: auto;
}

.sidebarTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.categoryButton {
  background: #f7f7f7;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;

}

.categoryButton:hover {
  background: #ececec;
}

.categoryActive {
  background: #6d00cc;
  color: #fff;
}

.templateRightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.searchBar {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.searchInput {
  width: 100%;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.4rem 0.5rem;
  width: 93%;
  padding: 10px;


}

.filterBtn {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.4rem;
  cursor: pointer;
  padding: 5px 10px;

}

.templatesGrid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  gap: 1rem;
  overflow-y: auto;
}

.templateCard {
  width: 180px;
  height: 200px; /* Adjust as needed */
  border: 1px solid #eee;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: box-shadow 0.2s;
  display: flex;
  flex-direction: column;
}

.templateCard:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.templateCardImg {
  width: 100%;
  height: 100px;
  background: #f0f0f0;
  border-radius: 6px 6px 0 0;
}

.templateCardContent {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  /* If text is too large, limit it:*/
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.templateCardTitle {
  font-weight: 600;
  font-size: 0.95rem;
  margin-bottom: 0.25rem;
}

.templateCardCategory {
  font-size: 0.8rem;
  color: #666;
}

.noTemplatesMessage {
  text-align: center;
  margin-top: 2rem;
  color: #666;
}

/* Loading spinner */
.loadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.loadingSpinner {
  width: 36px;
  height: 36px;
  border: 4px solid #ccc;
  border-top-color: #6d00cc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* 
----------------------------------------
 Preview Step
----------------------------------------
*/

/* Title for the entire template preview (e.g. "Preview Template: XYZ") */
.previewTitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #333;
}

/* The workflow's main description block */
.workflowDescription {
  margin-bottom: 1rem;
}

.workflowDescription h3 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  color: #333;
  font-weight: 600;
}

.workflowDescription p {
  margin: 0;
  color: #555;
  line-height: 1.4;
  font-size: 0.95rem;
}

/* The "Tasks" section heading */
.tasksSection {
  margin-bottom: 1rem;
}

.tasksSection h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
}

/* Each "panel" for one Task */
.taskPanel {
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: #fff;
}

/* The clickable header row that toggles open/close */
.taskPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #f7f7f7;
  cursor: pointer;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #eee;
  transition: background 0.2s;
}

.taskPanelHeader:hover {
  background-color: #f0f0f0;
}

/* The body content of a panel (visible only when isOpen = true) */
.taskPanelBody {
  padding: 1rem;
  background-color: #fff;
}

/* Optional styling for the task description */
.taskDescription {
  margin: 0 0 0.75rem 0;
  color: #555;
  line-height: 1.4;
  font-size: 0.95rem;
}

/* The fields area */
.taskFields p {
  margin: 0;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #333;
}

.taskFields ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fieldItem {
  margin: 0.25rem 0;
  color: #555;
  font-size: 0.9rem;
}

.fieldName {
  font-weight: 600;
}

.fieldType {
  font-style: italic;
  color: #666;
}

/* The "Use This Template" button */
.useTemplateBtn {
  display: inline-block;
  margin-top: 1rem;
  background-color: #6d00cc; /* your primary color */
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transition: background 0.2s;
  /* align to the right */
  margin-left: auto;
}

.useTemplateBtn:hover {
  background-color: #5800a0; /* darker purple on hover */
}

.actionLoadingOverlay {
  position: absolute;
  inset: 0;              /* fill the parent container */
  background-color: rgba(255,255,255,0.7); 
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999; /* above the rest */
}

/* The spinner in the center */
.actionLoadingSpinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ccc;
  border-top-color: #6d00cc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

/* Optional text “Processing...” or similar */
.actionLoadingText {
  font-size: 1rem;
  color: #333;
}

/* The spinner animation (already in your code, you can reuse) */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}