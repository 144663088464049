/********************************************************
 * Container
 ********************************************************/
 .cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;         /* Grid or parent defines width */
  min-height: 360px;   /* Enough space for the pinned footer */
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.cardContainer:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/********************************************************
 * Header (Image)
 ********************************************************/
.cardHeader {
  width: 100%;
  height: 160px;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.cardHeader:hover .cardImage {
  transform: scale(1.04);
}

/********************************************************
 * Body
 ********************************************************/
.cardBody {
  display: flex;
  flex-direction: column;
  flex: 1;             /* Push footer down if content grows */
  padding: 16px;
  gap: 8px;            /* Space between child elements */
  align-items: flex-start;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

/* Edit icon next to title */
.editIcon {
  margin-left: 8px;
  color: #6d00cc;
  text-decoration: none;
  transition: color 0.2s;
}

.editIcon:hover {
  color: #a64bd1;
}

/********************************************************
 * Category Pill
 ********************************************************/
.categoryPill {
  display: inline-block; /* Only as wide as text */
  font-size: 11px;
  font-weight: 500;
  background-color: #ebe7f9;
  color: #5b4f8b;
  padding: 4px 8px;
  border-radius: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: nowrap;   /* Prevent line wrap */
}

/********************************************************
 * Description
 ********************************************************/
.cardDescription {
  font-size: 13px;
  color: #444;
  line-height: 1.5;
  margin: 0;
  flex: 1;              /* So description can grow */
  overflow: hidden;
  text-overflow: ellipsis;
}

/********************************************************
 * Footer pinned to bottom
 ********************************************************/
.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;     /* Pin to bottom if body grows */
  padding: 12px 16px;
  border-top: 1px solid #eee;
  background-color: #fafafa;
}

.deleteButton {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #e53935;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.deleteButton:hover {
  background-color: #d32f2f;
  transform: scale(1.03);
}

.viewButton {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  color: #6d00cc;
  border: 2px solid #6d00cc;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
}

.viewButton:hover {
  background-color: #f5eefc;
  color: #6d00cc;
}

/********************************************************
 * Bottom Stats
 ********************************************************/
.bottomStats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #fafafa;
  border-top: 1px solid #eee;
}

.userCount,
.doneCount {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #555;
}
