/* src/styles/NavTabs.css */
.nav-tabs {
  margin-top: 15px;
  position: relative; /* Needed for the sliding underline */
}

.nav-tabs ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  position: relative; /* Added this line to enable absolute positioning of the underline */
}

.nav-tabs ul li {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.nav-tabs ul li.active a {
  font-weight: bold;
  color: #663399;
  border-bottom: 3px solid #663399;
}

.nav-tabs ul li a {
  text-decoration: none;
  color: #663399;
  font-size: 16px;
  padding: 5px 10px;
}

/* Sliding Underline */
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #663399;
  transition: all 0.3s ease;
}

/* Fade-In Animation for Content */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
