.homeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(45deg,
      rgba(123, 31, 162, 0.9),
      rgba(156, 39, 176, 0.9),
      rgba(186, 104, 200, 0.9));
  color: #2e2e2e;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  overflow: hidden;
}

/* Add these new background elements */
.backgroundAnimation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background:
    radial-gradient(circle at 20% 20%, rgba(156, 39, 176, 0.3) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(123, 31, 162, 0.3) 0%, transparent 50%),
    radial-gradient(circle at 50% 50%, rgba(186, 104, 200, 0.2) 0%, transparent 70%);
  animation: gradientShift 15s ease infinite;
  filter: blur(8px);
}

.particleContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: floatParticle 20s infinite linear;
}

.waveSection {
  position: relative;
  height: 100px;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 100px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23fff' fill-opacity='0.1' d='M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: repeat-x;
  animation: waveAnimation 20s linear infinite;
}

/* New animations */
@keyframes gradientShift {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes floatParticle {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: translateY(-1000px) translateX(100px);
    opacity: 0;
  }
}

@keyframes waveAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

/* ========== NAVBAR ========== */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* OLD: background-color: #6c3fcf; */
  background: rgba(123, 31, 162, 0.8);
  transition: background 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;
  padding: 0 2rem;
  backdrop-filter: blur(10px);
}

.navbarScrolled {
  /* OLD: background-color: #6c3fcf; */
  background: linear-gradient(to bottom right,
      #7B1FA2,
      #9C27B0,
      #BA68C8);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}


.navbarLeft {
  display: flex;
  align-items: center;
}

.navbarLogo {
  height: 40px;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}

.navbarTitle {
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
}

.navbarRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navLink {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

.navLink:hover {
  color: #ddd;
}

.navLink::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #fff;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.navLink:hover::after {
  width: 100%;
}

.languageSelector {
  padding: 0.3rem 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  outline: none;
  cursor: pointer;
}

/* ========== BUTTONS ========== */
.btn {
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  letter-spacing: 0.5px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Subtle scale and color shift */
.btnAnimated:hover {
  transform: scale(1.1);
  filter: brightness(1.05);
}

/* Link-like button */
.btnLink {
  background: none;
  color: #fff;
  padding: 0.6rem 1rem;
}

.btnLink:hover {
  color: #ddd;
}

/* Primary color button */
.btnPrimary {
  background-color: #8d4cf7;
  /* a bright purple */
  color: #fff;
  background: linear-gradient(135deg, #9c27b0, #ba68c8);
  box-shadow: 0 4px 15px rgba(156, 39, 176, 0.4);
}

.btnPrimary:hover {
  background-color: #6c3fcf;
  background: linear-gradient(135deg, #ba68c8, #9c27b0);
  box-shadow: 0 8px 25px rgba(156, 39, 176, 0.6);
}

/* White button with purple border */
.btnLight {
  background-color: #fff;
  color: #6c3fcf;
  border: 2px solid #6c3fcf;
  font-weight: 700;
}

.btnLight:hover {
  background-color: #6c3fcf;
  color: #fff;
}

/* ========== HERO SECTION ========== */
.heroSection {
  position: relative;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 64px;
  z-index: 2;
}

.heroOverlay {
  position: absolute;
  inset: 0;
  /* You can keep this overlay if you still want that subtle radial highlight,
     or remove it if you only want the pure gradient. */
  background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 0%, transparent 60%);
  z-index: 1;
}

.heroContent {
  z-index: 2;
  max-width: 600px;
  color: #fff;
  animation: floatIn 1s ease-out;
}

.heroTitle {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  display: inline-block;
  white-space: pre;
}

.cursor {
  display: inline-block;
  width: 1px;
  background: #fff;
  margin-left: 2px;
  animation: blink 0.8s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.heroText {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #f9f9ff;
}

/* ========== INSTRUCTIONS SECTION ========== */
.instructionsSection {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 20px auto;
  /* Changed: centered horizontally */
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  display: flex;
  /* Added: for better centering */
  flex-direction: column;
  /* Added: stack children vertically */
  align-items: center;
  /* Added: center children horizontally */
}

.instructionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  gap: 2rem;
  /* Start invisible/offscreen so we can fade in */
  opacity: 0;
  transition: opacity 0.8s ease, transform 0.8s ease;
  backdrop-filter: blur(8px);
  border-radius: 16px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.instructionItem:hover {
  transform: scale(1.02);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.reverseLayout {
  flex-direction: row-reverse;
}

.instructionContent {
  flex: 1;
}

.instructionTitle {
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
}

.instructionDesc {
  font-size: 1rem;
  color: #f9f9ff;
  line-height: 1.6;
  max-width: 500px;
}

.instructionImage {
  flex: 1;
  text-align: center;
}

.instructionImage img,
.instructionImage video {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
  transition: transform 0.5s ease;
  filter: brightness(1.05) contrast(1.05);
}

.instructionImage:hover img {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

/* ========== CTA SECTION ========== */
.ctaSection {
  background: rgba(156, 39, 176, 0.4);
  color: #fff;
  padding: 3rem 1rem;
  text-align: center;
  backdrop-filter: blur(8px);
}

.ctaContent {
  max-width: 600px;
  margin: 0 auto;
}

.ctaTitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.ctaText {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

/* ========== FOOTER ========== */
.footerSection {
  background-color: #7B1FA2;
  padding: 1.5rem 1rem;
  margin-top: auto;
  background: linear-gradient(to right, #7b1fa2, #9c27b0);
  position: relative;
  overflow: hidden;
}

.footerSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent);
}

.footerContent {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.footerText {
  font-size: 0.9rem;
}

.footerLinks {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.footerLinks a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

/* ========== FADE & SCROLL ANIMATIONS ========== */
/* We add or remove these classes in IntersectionObserver. */

/* Fade In from Left */
.fadeInLeft {
  opacity: 1;
  transform: translateX(0) !important;
}

.fadeOutLeft {
  opacity: 0;
  transform: translateX(-50px) !important;
}

/* Fade In from Right */
.fadeInRight {
  opacity: 1;
  transform: translateX(0) !important;
}

.fadeOutRight {
  opacity: 0;
  transform: translateX(50px) !important;
}

/* On smaller screens, instructions stack vertically */
@media (max-width: 768px) {

  .instructionItem,
  .reverseLayout {
    flex-direction: column;
    text-align: center;
  }

  .instructionImage {
    margin-top: 1.5rem;
  }

  .heroTitle {
    font-size: 2rem;
  }
}

/* ========== STATS SECTION ========== */
.statsSection {
  padding: 3rem 1rem;
  background: rgba(123, 31, 162, 0.3);
  text-align: center;
  backdrop-filter: blur(5px);
}

.statsTitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #ffffff;
  /* Changed: from #6c3fcf to white */
  animation: fadeInUp 0.8s ease forwards;
  background: linear-gradient(135deg, #fff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1100px;
  margin: 0 auto;
  perspective: 1000px;
}

.statCard {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform-style: preserve-3d;
  perspective: 1000px;
}

.statCard:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(5deg);
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 5px 15px rgba(0, 0, 0, 0.1);
}

.hoverEffect:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.statIcon {
  font-size: 2rem;
  color: #6c3fcf;
  margin-bottom: 1rem;
}

.statCount {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #333;
}

.statLabel {
  font-size: 1rem;
  color: #666;
}

/* Keyframes for fadeInUp, reusing your existing or define a new one */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Smooth Scroll Reveal */
@keyframes revealFromBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.revealOnScroll {
  opacity: 0;
  animation: revealFromBottom 0.8s ease forwards;
}

/* Floating Elements Animation */
@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}