
.fieldLabel {
  width: 90%; /* Full width for the textarea */
  background: transparent;
  border: none;
  resize: none;
  color: #565555;
  font-size: 15px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  outline: none;
  white-space: pre-wrap;
  overflow-y: none;
  font-family: 'montserrat', sans-serif;
  /* make the height is just as the height of the text */
  height: 30px;


}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radioOption {
  display: flex;
  align-items: center;
  width: 90%;
  gap: 6px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.radioOption:hover {
  background-color: #f5f5f5;
  border-color: #6d00cc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.radioOption input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioOption input[type="radio"]:checked {
  border-color: #ccc;
  background-color: #6d00cc;
  box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.5);
  
}

.radioOption input[type="radio"]:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
}

.separator {
    margin-top: 0px;
    border-top: 1px dashed #ddd;
    width: 100%;
    
}

.choicesEditorSection {
  margin-top: 16px;
}

.addButton {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #0056b3;
}

.choicesList {
  margin-top: 12px;
  padding: 0;
  list-style: none;
}

.choiceItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.choiceInput {
  flex: 1;
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.choiceInput:focus {
  outline: none;
  border-color: #007bff;
}



.moveButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moveButton:hover {
  background-color: #0056b3;
}

.moveButton:disabled {
  background-color: #bbb;
    cursor: default;
}

.deleteButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton:hover {
  background-color: #c82333;
}


.helpText {
    font-size: 11px;
    margin-bottom: 0px;
    margin-top:5px;
    border: none;
    background: transparent;
    resize: none;
    overflow: hidden;
    color: #333;
    cursor: pointer;
    outline: none;
    width: 90%;
    white-space: pre-wrap; 
    overflow-y: auto;
    padding-bottom: 0;
}

.helpText:focus {
    border-color: #007bff; /* Highlight border on focus */
    outline: none;
}