:root {
    --accent-color: #3A7BD5;
    --border-color: #ddd;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --shadow-light: rgba(0,0,0,0.05);
    --shadow-medium: rgba(0,0,0,0.1);
    --spinner-color: #3A7BD5;
    --bg-light: #ffffff;
    --text-color: #333;
    --heading-color: #2A4A7B;
}

.formContainer {
    background: var(--bg-light);
    border-radius: 12px;
    box-shadow: 0 4px 12px var(--shadow-light);
    padding: 30px;
    font-family: var(--font-family);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    animation: fadeIn 0.3s ease forwards;
    opacity: 0;
}

@keyframes fadeIn {
    to { opacity: 1; }
}

.formHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 30px;
}

.stepInfo {
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 500;
}

.stepTitle {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--heading-color);
    margin: 0;
}

.singleStepTitle {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--heading-color);
    margin: 0;
}

.stepProgressBar {
    width: 100%;
    background: #eee;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
}

.stepProgressFill {
    height: 100%;
    background: var(--accent-color);
    transition: width 0.4s ease-in-out;
}

.fieldsWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.fieldGroup {
    position: relative;
    opacity: 0;
    transform: translateY(10px);
    animation: slideInUp 0.4s ease forwards;
}

@keyframes slideInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.savingIndicator {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    font-size: 1rem;
    color: #555;
    font-style: italic;
    margin-top: 20px;
    animation: fadeIn 0.3s ease;
}

.spinnerIcon {
    animation: spin 1s linear infinite;
    width: 20px;
    height: 20px;
    color: var(--spinner-color);
}

.spinnerCircle {
    opacity: 0.25;
    stroke: currentColor;
}

.spinnerPath {
    opacity: 0.75;
    fill: currentColor;
}

@keyframes spin {
    100% { transform: rotate(360deg); }
}
