/* Main Configuration Window */
.configWindow {
  position: fixed;
  top: var(--config-window-top, 60px); /* Default to 60px when original navbar is hidden */
  right: 0;
  width: 310px;
  height: calc(100% - var(--config-window-top, 60px));
  background-color: #ffffff;
  color: #333;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  /* Start off screen to the right */
  transform: translateX(100%);
  transition: transform 0.5s ease, top 0.5s ease, height 0.5s ease;
}

.configWindowOpen {
  /* Slide in to the visible area */
  transform: translateX(0);
}


/* Header Section */
.header {
  margin-top: 10px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

/* Tab Container */
.tabContainer {
  display: flex;
  padding: 0px;
  margin-bottom: 5px;
  background-color: #f7f7f8;
  border-bottom: 1px solid #e0e0e0;
}

.tabContainer button {
  flex: 1;
  background-color: #f7f7f8;
  border: none;
  padding: 10px;
  color: #888;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.tabContainer button:hover {
  color: #6d00cc; /* Active tab highlight */
}

/* Active Tab Styling */
.tabContainer .activeTab {
  color: #6d00cc; /* Active tab highlight */
  border-bottom: 2px solid #6d00cc;
}

/* Tab Content */
.tabContent {
  padding: 15px;
  overflow-y: auto;
  height: calc(100% - 140px); /* Subtract header + tabs height */
  margin-bottom: 60px;
  
  /* Add custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #6d00cc #f1f1f1;

}

/* Custom scrollbar for webkit browsers */
.tabContent::-webkit-scrollbar {
  width: 6px;
}

.tabContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tabContent::-webkit-scrollbar-thumb {
  background: #6d00cc;
  border-radius: 3px;
}

.tabContent::-webkit-scrollbar-thumb:hover {
  background: #5b00ad;
}

/* Form Group Styling */
.formGroup {
  margin-bottom: 20px;
  padding-right: 10px; /* Reduced padding to account for scrollbar */
}

.formGroup label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.formGroup input[type="text"],
.formGroup input[type="number"],
.formGroup input[type="date"],
.formGroup select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
}


.toggleCheckbox {
  appearance: none;
  -webkit-appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.4s;
}

.toggleCheckbox::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: transform 0.4s;
}

.toggleCheckbox:checked {
  background-color: #6d00cc;
}

.toggleCheckbox:checked::before {
  transform: translateX(20px);
}

.fieldTextArea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  resize: vertical; 
  overflow-x: auto; 
}

.description {
  font-size: 0.85rem;
  color: #666;
  margin-top: 5px;
}

.multiCheckboxContainer {
  position: relative;
}

.multiCheckboxSpinner {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multiCheckboxDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 2px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  z-index: 200;
  max-height: 150px;
  overflow-y: auto;
}

.multiCheckboxItem {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 5px;
  cursor: pointer;
}

.standardCheckbox {
  margin-right: 10px;
  /* Standard checkbox appearance */
  appearance: checkbox;
  -webkit-appearance: checkbox;
  width: auto;
  height: auto;
  cursor: pointer;
}

.checkboxNote {
  font-size: 0.8rem;
  color: #666;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* spinner */
.spinnerContainer {
  position: relative;
  
}


/* .customSpinner {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
}

.customSpinner:hover {
  border-color: #007bff;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.customSpinner:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

.customSpinner option {
  padding: 8px;
  background-color: #fff;
  font-size: 14px;
  font-family: inherit;
}

.customSpinner option:hover {
  background-color: #f0f8ff;
} */