/***************************************
 * Outer Container
 ***************************************/
.outerDashboardContainer {
  display: flex;
  margin-bottom: 40px;
  padding-left: 60px;
}

/***************************************
 * Inner Content
 ***************************************/
.innerDashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
}

/***************************************
 * Dashboard Header
 ***************************************/
.dashboardHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #e3e4e8;
  margin: 0 -2rem 0rem;
  padding: 20px 2rem 10px 2rem;
  align-items: flex-start;
}

/***************************************
 * Workflow Grid Section
 ***************************************/
.workflowGridSection {
  position: relative;
  min-height: 200px;
  margin-bottom: 2rem;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 10px;
  zoom: 0.7;
}

/***************************************
 * Loading Block
 ***************************************/
.loadingBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.spinner {
  width: 35px;
  height: 35px;
  border: 5px solid #eee;
  border-top: 5px solid #663399;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loadingText {
  color: #555;
  font-size: 1rem;
}

/***************************************
 * Workflow Grid
 ***************************************/
.workflowGrid {
  display: grid !important;
  /* Increased min to 320px for a larger card */
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;
}

.workflowGrid>div {
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0;
  /* start hidden */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/***************************************
 * Empty State
 ***************************************/
.emptyBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.emptyIcon {
  width: 60px;
  margin-bottom: 10px;
  opacity: 0.8;
}

.emptyText {
  font-size: 1.2rem;
  color: #777;
}

.rtl {
  direction: rtl;
}

.outerDashboardContainer.rtl {
  padding-right: 60px;
  padding-left: 0px;
}