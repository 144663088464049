.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modalHeader {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  padding: 20px;
  overflow-y: auto;
}

.modalFooter {
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.fieldGroup {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.fieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.checkboxContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.hiddenCheckbox:checked ~ .checkmark {
  background: #0066cc;
  border-color: #0066cc;
}

.hiddenCheckbox:checked ~ .checkmark:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.multipleMatches {
  margin-left: 28px;
  padding-left: 12px;
  border-left: 2px solid #eee;
}

.suggestionItem {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 12px 0;
  padding: 12px 12px 12px 40px; /* Increased left padding for radio button */
  background: #f8f9fa;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.suggestionItem:hover {
  background: #f0f0f0;
  transform: translateX(4px);
}

.suggestionItem.selected {
  background: #f0f7ff;
  border-left: 3px solid #0066cc;
}

.suggestionRadio {
  margin-top: 4px;
}

.suggestionLabel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  position: relative; /* Added */
  width: 100%; /* Added */
}

.suggestionLabel:before {
  content: '';
  position: absolute;
  left: -28px;  /* Align with hidden radio */
  top: 50%;
  transform: translateY(-50%);
  width: 18px; /* Increased from 16px for better visibility */
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 50%;
  background: white;
  transition: all 0.2s ease;
  box-sizing: border-box; /* Added to ensure consistent sizing */
}

.hiddenRadio:checked + .suggestionLabel:before {
  border-color: #0066cc;
}

.hiddenRadio:checked + .suggestionLabel:after {
  content: '';
  position: absolute;
  left: -22px; /* Adjusted to center the dot */
  top: 50%;
  transform: translateY(-50%);
  width: 6px; /* Reduced for better proportion */
  height: 6px;
  border-radius: 50%;
  background: #0066cc;
  transition: all 0.2s ease;
}

.hiddenRadio {
  position: absolute;
  left: -28px; /* Position radio button to the left */
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.suggestionHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
}

.suggestionDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.selectedIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #0066cc;
}

.modalInstructions {
  padding: 16px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.instructionItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  color: #666;
  font-size: 0.9em;
}

.instructionIcon {
  color: #0066cc;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.multipleMatchesBadge {
  font-size: 0.8em;
  background: #e9ecef;
  padding: 4px 8px;
  border-radius: 12px;
  color: #495057;
}

.radioWrapper {
  position: relative;
}

.radioCustom {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.suggestionRadio:checked ~ .radioCustom {
  border-color: #0066cc;
}

.suggestionRadio:checked ~ .radioCustom:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
  background: #0066cc;
  border-radius: 50%;
}

.hiddenCheckbox,
.hiddenRadio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.confidence {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.confidence.high {
  background: #d4edda;
  color: #155724;
}

.confidence.medium {
  background: #fff3cd;
  color: #856404;
}

.confidence.low {
  background: #f8d7da;
  color: #721c24;
}

.confirmButton {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.value {
  font-weight: 500;
  font-size: 1.1em;
}

.source {
  color: #666;
  font-size: 0.9em;
}

.reasoning {
  color: #666;
  font-size: 0.85em;
  font-style: italic;
}

.suggestionsList {
  margin-left: 28px;
  padding-left: 12px;
  border-left: 2px solid #eee;
}

.suggestionsList.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.suggestionsList.active {
  opacity: 1;
  pointer-events: auto;
}

.confirmDescription {
  margin: 0;
  color: #666;
  font-size: 0.95em;
  line-height: 1.4;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.headerContent {
  flex: 1;
}

.modalTitle {
  margin: 0 0 8px 0;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
}
