.animatedEdge {
  fill: none;
  stroke-width: 3;
  stroke-dasharray: 6;
  animation: dashOffset 1s linear infinite; 
}

@keyframes dashOffset {
  100% {
    stroke-dashoffset: -24;
  }
}
