.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1050px;
    height: 650px;
    display: flex;
    background: white;
    border-radius: 100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.leftPanel, .rightPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 100px;

}

.lineSeparator {
    width: 70%;
    background-color: #ccc;
    margin-bottom: 20px;
    height: 0.1px;
   
}

.leftPanel {
    background-color: #512da8;
    color: white;
}

.rightPanel {
    background: white;
}

.formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    color: black;
    margin-bottom: 10px;
}

.text {
    margin-bottom: 10px;
}

.input, .signInButton, .googleSignIn, .toggleButton {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: block;
}

.input {
    border: 2px solid #ccc;
}

.inputSelect {
    width: 85%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 2px solid #ccc;
    background-color: white;
}

.signInButton {
    background-color: #5a4d9f;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
}

.signInButton:hover {
    background-color: #473d80;
}

.googleSignIn {
    background-color: transparent;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50px;
}

.googleSignIn:hover {
    background-color: #f1f1f1;
}

.googleIcon {
    margin-right: 1px;
}
.header{
    color:white;

}
.headerCreate{
    color:black;
}

.text{
    color:white;
}
.toggleButton {
    background-color: #512da8;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 200px;
    border: 1px solid transparent;
    border-color: #fff;

   
}

.toggleButton:hover {
    background-color: #512da8;
}
