/* File: SignatureField.module.css */

.signatureFieldWrapper{
  width: 95%;
  margin-bottom: 0;
}



/* Label area (similar to your text field's approach) */
.labelWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.labelContainer {
  position: relative;
  width: 100%;
}

.fieldLabel {
  width: 90%; 
  background: transparent;
  border: none;
  resize: none;
  color: #565555;
  font-size: 15px;
  font-weight: 600;
  font-family: inherit;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
  white-space: pre-wrap;
  overflow-y: none;

}




/* Tab header */
.tabHeader {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tabButton {
  background: none;
  border: none;
  padding: 8px 14px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #666;
  transition: background 0.2s;
  outline: none;
}

.tabButton:hover {
  background-color: #f2f2f2;
}

.activeTab {
  border-bottom: 3px solid #6d00cc;
  color: #6d00cc;
}

/* Tab content container */
.tabContent {
}

.drawTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

/* The signature pad placeholder (a light box) */
.signaturePadPlaceholder {
  position: relative;       /* so we can absolutely-position the elements inside */
  width: 100%;
  height: 120px;            /* you can adjust height as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(0,0,0,0.05);
}

/* "Sign Here" container (centered in the pad) */
.signHereContainer {
  position: absolute;
  top: 50%;                 /* center vertically */
  left: 50%;                /* center horizontally */
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  color: #ccc;
  font-size: 0.95rem;
  gap: 6px;                 /* space between icon and text */
}

/* The grey line near the bottom */
.signatureLine {
  position: absolute;
  bottom: 34px;             /* adjust how far up from bottom you want the line */
  left: 20px;
  right: 20px;
  border-bottom: 2px solid #ddd;  /* thicker line, slightly darker grey */
}

/* The "Clear" button inside the pad, bottom-right */
.clearButton {
  position: absolute;
  bottom: 8px;
  right: 15px;
  background: transparent;
  color: #6d00cc;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: not-allowed;       /* disabled in the admin preview */
  opacity: 0.6;
  font-size: 0.85rem;
  text-decoration-line: underline;
}

/* Type Tab */
.typeTab {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.typedSignaturePreview {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #666;
}

.fontStyles select {
  padding: 5px 8px;
  font-size: 0.9rem;
}

/* Upload Tab */
.uploadTab {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.uploadButton {
  padding: 6px 12px;
  background: #ccc;
  border: 1px solid #999;
  border-radius: 4px;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Help text area */
.helpText {
  width: 100%;
  font-size: 0.85rem;
  border: none;
  resize: none;
  color: #666;
  outline: none;
  padding: 4px;
}
