/* Sidebar Styles */
.sidebar {
  width: 40px;
  height: 100vh;
  background: linear-gradient(180deg, #b55dcd 0%, #724ebf 100%);
  color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px;
  z-index: 1000;
  transition: width 0.4s ease-in-out;
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}

.sidebar.expanded {
  width: 250px;
}

.site-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
  transition: margin-bottom 0.3s ease;
}

.site-header:hover {
  cursor: pointer;
}

.logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  transition: width 0.3s ease, height 0.3s ease;
}

.menu-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  /* Ensures the image fits nicely within the circle */
  border: 2px solid #fff;
  /* Optional: a small border for a cleaner look */
}

.sidebar.collapsed .menu-img {
  /* Possibly reduce size when collapsed */
  width: 30px;
  height: 30px;
}

.site-title {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: white;
  letter-spacing: 1px;
  margin-left: 10px;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

.menu-container {
  flex-grow: 1;
}

.menu-items {
  list-style: none;
  padding: 0;
}

.menu-items li {
  margin-bottom: 20px;
}


.bottom-menu-items {
  list-style: none;
  padding: 0;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  font-size: 15px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.sidebar-link.active {
  background-color: rgba(255, 255, 255, 0.3);
}

.menu-icon {
  font-size: 22px;
}

.sidebar-link span {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .site-title,
.sidebar.collapsed .sidebar-link span:not(.badge) {
  opacity: 0;
  pointer-events: none;
}

.sidebar.collapsed .sidebar-link {
  justify-content: center;
}

.nav-divider {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin: 20px 0;
}

.content-wrapper {
  margin-left: 78px;
  transition: margin-left 0.4s ease-in-out;
  padding: 20px;
}

.sidebar.expanded~.content-wrapper {
  margin-left: 275px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .sidebar {
    width: auto;
    /* You might consider keeping it expanded on touch devices */
  }

  .sidebar.expanded {
    width: 275px;
  }

  .content-wrapper,
  .sidebar.expanded~.content-wrapper {
    margin-left: 78px;
  }
}

/* Positioning for the bell + badge */
.bell-container {
  position: relative;
  display: inline-block;
  /* keep icon + badge together */
}

/* The red badge that shows the unread count */
.badge {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #dc3545;
  /* "danger" red */
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
}

/* The "enhanced" shake-bounce animation */
@keyframes bellShake {

  0%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  10% {
    transform: rotate(-15deg) scale(1.02);
  }

  20% {
    transform: rotate(15deg) scale(1.02);
  }

  30% {
    transform: rotate(-10deg) scale(1.02);
  }

  40% {
    transform: rotate(10deg) scale(1.02);
  }

  50% {
    transform: rotate(-5deg) scale(1.05);
  }

  60% {
    transform: rotate(5deg) scale(1.05);
  }

  70% {
    transform: rotate(0deg) scale(1.1);
  }

  80% {
    transform: rotate(0deg) scale(1.05);
  }

  90% {
    transform: rotate(0deg) scale(1.08);
  }
}

/* Apply the animation to the container if unreadCount > 0 */
.bell-shake {
  animation: bellShake 1.8s ease-in-out infinite;
}