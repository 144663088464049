.workflowBuilder {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  min-height: 50vh;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .workflowBuilder {
    padding: 0 20px;
    margin-top: 100px;
  }
  
}

.workflowInfo, .formGroup {
  display: none;
}

.workflowHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top:20px;
  width: 100%;
  gap: 20px;
}



.workflowInput {
  flex: 1;
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  padding: 12px 20px;
  background: transparent;
  transition: all 0.3s ease;
  border-color: transparent;


}

.workflowInput:focus {
  background: transparent;
  border-color: transparent;

  border: none;
}

.workflowInput::placeholder {
  color: #a9a9a9;
}

/* Center Section: Task Name and Description */
.taskTitleWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
  padding-right: 16px;
}

.workflowTextArea {
  resize: none;
  height: 30px;
  overflow-y: hidden;
}


.quillEditor {
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 52%;
  resize: vertical;
  background-color: white;
}

.deleteTaskButton {
  color: #333;
  opacity: 1;
  padding: 4px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.1em;
  transition: color 0.2s ease;
  position: static;
}

.deleteTaskButton:hover {
  color: #dc3545;
}

.task:hover .deleteTaskButton {
  opacity: 1;
}

.charCounter {
  display: block;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.exceeded {
  color: red;
}

.error {
  border-color: red;
}

.tasksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Limit the width of the tasks section */
  margin: 0 auto;
}

.addTaskButtonOriginal {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #6d00cc;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(109, 0, 204, 0.2);
}

.addTaskButtonOriginal i {
  font-size: 18px;
}

.addTaskButtonOriginal:hover {
  background-color: #5b00ad;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(109, 0, 204, 0.3);
}

.addTaskButtonOriginal:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(109, 0, 204, 0.2);
}

.task {
  margin-bottom: 12px;
  background-color: #ffffff; /* White background for a cleaner look */
  border: 1px solid #e0e0e0;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
  padding: 16px; /* Add padding for content spacing */
  width: 100%; /* Allow tasks to adapt to grid cells */
  transition: all 0.2s ease; /* Smooth transition for hover effect */
}

.task:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); /* Slightly larger shadow on hover */
}

.task.collapsed {
  padding: 12px; /* Reduced padding when collapsed */
  padding-bottom: 0px;
  transform: none; /* Slightly smaller scale when collapsed */
  opacity: 0.9; /* Slightly more transparent when collapsed */
}

.task.collapsed:hover {
  transform: translateY(-1px);
}

.dragFieldPlaceHolder {
  color: #6c757d;
  font-style: italic;
  text-align: center;
  padding: 20px;
  border: 2px dashed #e0e0e0 ;
  border-radius: 8px;
  margin-bottom: 0px;
  background-color: white;
  transition: all 0.3s ease;
}

.dragOver{
  background-color: #f9f9f9;
  border: 2px dashed #6d00cc; /* Blue dashed border for drop area */

  /*
  remove the left and right border when dragging over the drop area
  */
  border-left: none;
  border-right: none;
  border-bottom: none;

  color: #6d00cc; /* Blue text color for drop area */
}


.dragOverWhole{
  background-color: #f9f9f9;
  border: 2px dashed #6d00cc; /* Blue dashed border for drop area */
  
}


.taskHeader {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  position: relative;


}

/* Left Section: Drag Handle and Delete Button */
.taskHeaderLeft {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  gap: 12px;
  margin-top: 5px;
}

/* Right Section: Collapse Button */
.taskHeaderRight {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-top: 10px;
}


/* Task drag handle styling */
.taskDragHandle {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 4px;
  cursor: move;
  margin-right: 12px;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  margin-top: 5px;
  margin-right: 0px;

}

.taskDragHandle:hover {
  opacity: 1;
}

.taskDragHandle span {
  display: flex;
  gap: 3px;
}

.taskDragHandle i {
  width: 3px;
  height: 3px;
  background-color: #333;
  border-radius: 50%;
}


.collapseButton {
  color: #888;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 4px;
  transition: color 0.3s ease;
}

.collapseButton:hover {
  color: #555;
}

.collapsedTaskFields {
  padding-bottom: 0;
  padding: 0; /* Remove padding when collapsed */
  margin: 0; /* Remove margin when collapsed */
  padding-bottom: 0; /* Remove padding when collapsed */
  border: none;
  background-color: transparent; /* Make background transparent when collapsed */
  transition: height 0.5s ease-in-out, padding 0.5s ease;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.taskNameField {
  font-weight: 700;
  font-size: 1.1rem; /* Slightly larger font size */
  color: #333;
  border: none;
  outline: none;
  background-color: transparent; /* Transparent background */
  width: 600px;
  font-family: 'montserrat', sans-serif;
  padding: 0;
  margin: 0;
}

.taskNameField:focus {
  &::placeholder {
    color: transparent;
  }
}

.taskDescriptionField {
  font-size: 14px;
  color: #666;
  border: none;
  outline: none;
  background-color: transparent; /* Transparent background */
  width: 100%;
  resize: none;
  line-height: 1.4;
  padding: 0;
  height: auto;
  margin-top: 2px;
  font-family: 'montserrat', sans-serif;
  font-weight: 600;
}

.taskDescriptionField:focus, .taskNameField:focus {
  &::placeholder {
    color: transparent;
  }
}

/* Task Fields Container */
.taskFields {
  margin-top: 4px; /* Reduced margin */
  border-top: none;
  background-color: transparent;
}

.taskFields.dragOverWhole{
  background-color: #f9f9f9;
  border-radius: 10px;
}

/* Container for the field */
.taskField {
  position: relative;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: white;
}

.taskField.selected {
  border: 2px solid #6d00cc;
}

.draggableField {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  position: relative;
}

/* Drag handle (six dots) */
.dragHandle {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: move;
  font-size: 1.5em;
  font-weight: bold;
  color: #ccc;
  color: #888;
  cursor: move;
  font-size: 1.2em;
  padding: 4px;
  margin-top: 2px;
  z-index: 1000;
}

/* Make the drag handle visible when the field is selected */
.taskField.selected .dragHandle {
  display: block;
}

.dragging {
  background-color: #e0e0e0;
}

/* Configure and delete buttons */
.fieldOptions {
  position: absolute;
  top: 50%;
  right: -50px; 
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;  
}

/* Shared styling for buttons */
.fieldOptions button {
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;
  transition: all 0.3s ease;
}

/* Configure button styling */
.editFieldButton {
  background-color: #3a3a3a;
  color: #fff;
  position: relative; /* To position the extended Properties tooltip */
}

/* Tooltip styling to look like an extension of the button */
.editFieldButton::after {
  content: 'Properties';
  position: absolute;
  top: 0;
  left: 50%; /* Adjusted positioning to fit better */
  background-color: #3a3a3a;
  color: #fff;
  border-radius: 0 20px 20px 0; /* Half-rounded to look like an extension */
  padding: 0 20px; /* Extra padding for smoother transition between icon and text */
  height: 40px; /* Same height as the button */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transition: all 0.3s ease;
  z-index: -1; /* Make sure it stays on top of other elements */
}

/* Show the tooltip when hovering over the button */
.editFieldButton:hover::after {
  visibility: visible;
  opacity: 1;
}

/* Delete button styling */
.deleteFieldButton {
  background-color: #f44336;
  color: #fff;
  position: relative; 
}

/* Tooltip styling for 'Delete' */
.deleteFieldButton:hover::after {
  content: 'Delete';
  position: absolute;
  left: 50%;
  top: 0;
  background-color: #f44336;
  color: #fff;
  border-radius: 0 20px 20px 0;
  padding: 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: -1;
}

.deleteFieldButton:hover::after {
  visibility: visible;
  opacity: 1;
}

.addTaskBetween {
  position: relative;
  height: 5px; /* Reduced height for smaller gap */
  margin: 5px 0; /* Smaller gap between tasks */
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTaskButton {
  background-color: #6d00cc;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  opacity: 0; /* Hidden initially */
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-150%, -80%); /* Center horizontally and vertically */
  transition: all 0.3s ease;
  z-index: 1;
}

.addTaskBetween:hover .addTaskButton {
  opacity: 1; 
}

.addTaskBetween::before {
  content: '';
  position: absolute;
  top: 30%;
  left: 0;
  width: 70%;
  height: 1px;
  background-color: transparent; 
  transition: background-color 0.3s ease;
  margin-left: 10px; 
}

.addTaskBetween:hover::before {
  background-color: #ccc; 
}


.workflowBuilderText i {
  font-size: 12px;
  color: #555;
}
