.versionedCard {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 360px;  /* Fixed minimum height */
}

.versionedCard.expanded {
  box-shadow: none;
  border-color: #2196F3;
}

.mainCard {
  cursor: pointer;
  padding: 15px;
}

.cardHeader {
  position: relative;
  width: 100%;
  height: 200px;  /* Fixed height for image container */
  background: #f5f5f5;  /* Background for when there's no image */
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 8px;
}

.versionBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.versionsList {
  border-top: 1px solid #eee;
  max-height: 300px;
  overflow-y: auto;
}

.versionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid #f5f5f5;
}

.versionInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: linear-gradient(transparent, rgba(0,0,0,0.7));
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.versionNumber {
  font-weight: 600;
}

.latestBadge {
  background: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.versionActions {
  display: flex;
  gap: 10px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: white;
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
}

.deleteButton {
  composes: actionButton;
  color: #dc3545;
  border-color: #dc3545;
}

.stats {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
}

.baseVersion {
  font-size: 0.9rem;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
}

.versionCount {
  background: #2196F3;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.cardBody {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cardTitle {
  margin: 0;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandIcon {
  color: #666;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.categoryPill {
  display: inline-block;
  padding: 4px 12px;
  background: #f0f0f0;
  border-radius: 16px;
  font-size: 0.85rem;
  color: #666;
  margin-top: 8px;
}

.cardDescription {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.userCount {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Hover effects - modify these */
.versionedCard:hover {
  border-color: #2196F3; /* Change to blue border instead of shadow */
  transform: none; /* Remove the lift effect */
  box-shadow: none;
}

.versionedCard:hover .cardImage {
  transform: scale(1.05);
}

.versionedCard:hover .expandIcon {
  transform: rotate(180deg);
}
