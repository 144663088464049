:root {
    --brand-color: #2A4A7B;
    --accent-color: #3A7BD5;
    --success-color: #28a745;
    --error-color: #d9534f;
    --bg-light: #f8f9fc;
    --bg-gradient: linear-gradient(135deg, #f9fcff, #eef2f7);
    --text-dark: #333;
    --border-color: #ddd;
    --transition-duration: 0.3s;
    --ease-out: cubic-bezier(0.4, 0, 0.2, 1);
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --shadow-light: rgba(0, 0, 0, 0.05);
    --shadow-medium: rgba(0, 0, 0, 0.1);
}

.container {
    max-width: 1100px;
    margin: 40px auto;
    background: var(--bg-gradient);
    border-radius: 12px;
    padding: 20px;
    font-family: var(--font-family);
    position: relative;
    box-shadow: 0 4px 12px var(--shadow-light);
    transition: box-shadow 0.3s ease;
}

.container:hover {
    box-shadow: 0 6px 16px var(--shadow-medium);
}

.topBar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.backLinkButtonTop {
    background: none;
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background var(--transition-duration) var(--ease-out), transform var(--transition-duration) var(--ease-out);
    text-decoration: none;
}

.backLinkButtonTop:hover {
    background: var(--accent-color);
    color: #fff;
    transform: scale(1.05);
}

.stepNav {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: slideDownFade 0.5s var(--ease-out) forwards;
    transform: translateY(-20px);
}

@keyframes slideDownFade {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.stepLine {
    display: flex;
    align-items: center;
    position: relative;
    gap: 30px;
}

.stepItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    outline: none;
    transition: transform var(--transition-duration) var(--ease-out), box-shadow var(--transition-duration) var(--ease-out);
}

.stepItem:focus .stepCircleFuture,
.stepItem:focus .stepCircleCurrent,
.stepItem:focus .stepCircleCompleted {
    box-shadow: 0 0 0 3px rgba(42, 74, 123, 0.3);
}

.stepCircleFuture,
.stepCircleCurrent,
.stepCircleCompleted {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: background-color var(--transition-duration) var(--ease-out), color var(--transition-duration) var (--ease-out), transform var(--transition-duration) var(--ease-out);
    transform-origin: center;
    font-size: 1.1rem;
    width: 44px;
    height: 44px;
}

.stepCircleCurrent {
    background: var(--accent-color);
    color: #fff;
    border: 2px solid var(--accent-color);
    box-shadow: 0 4px 8px var(--shadow-light);
    transform: scale(1.1);
}

.stepCircleCompleted {
    background: var(--success-color);
    color: #fff;
    border: 2px solid var(--success-color);
    box-shadow: 0 4px 8px var(--shadow-light);
}

.stepCircleFuture {
    background: #fff;
    border: 2px solid #ccc;
    color: #999;
}

.stepItem:hover .stepCircleFuture {
    border-color: var(--accent-color);
    color: var(--accent-color);
    transform: scale(1.15);
}

.stepItem:hover {
    transform: scale(1.05);
}

.stepConnector {
    width: 60px;
    height: 2px;
    background: #ccc;
    transition: background-color var(--transition-duration) var(--ease-out);
}

.pageWrapper {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px var(--shadow-light);
    padding: 40px;
    position: relative;
    animation: fadeIn 0.3s var(--ease-out) forwards;
    opacity: 0;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.headerSection {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
}

.title {
    color: var(--accent-color);
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.titleIcon {
    font-size: 1.6rem;
}

.description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
}

.taskStatus {
    font-size: 1rem;
    color: #444;
    margin-bottom: 20px;
}

.progressSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.taskProgress {
    font-size: 1.1rem;
    color: #444;
    font-weight: 500;
}

.progressBar {
    width: 100%;
    background: #eee;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.progressFill {
    height: 100%;
    background: var(--accent-color);
    transition: width 0.4s ease-in-out;
}

.formContainer {
    margin-bottom: 40px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.transitionInForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    width: 40px;
    height: 40px;
    border: 5px solid #eee;
    border-top: 5px solid var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.loadingText {
    color: #555;
    font-size: 1rem;
    text-align: center;
}

.footerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f6f8;
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 40px;
    position: relative;
    box-shadow: 0 4px 8px var(--shadow-light);
}

.navButton {
    background: var(--accent-color);
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background var(--transition-duration) var(--ease-out), transform var(--transition-duration) var(--ease-out);
    transform-origin: center;
    display: inline-flex;
    align-items: center;
    position: relative;
    box-shadow: 0 4px 8px var(--shadow-light);
}

.navButton:hover {
    background: #2a66a3;
    transform: translateY(-2px);
}

.prevButton {
    background: #555;
    box-shadow: 0 4px 8px var(--shadow-light);
}

.prevButton:hover {
    background: #333;
}

.submitButton {
    background-color: var(--success-color);
    box-shadow: 0 4px 8px var(--shadow-light);
}

.submitButton:hover {
    background-color: #219638;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: var(--font-family);
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal {
    background-color: #fff;
    border-radius: 12px;
    min-width: 320px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transform: translateY(-20px);
    opacity: 0;
    animation: slideFadeIn 0.3s ease-out forwards;
}

@keyframes slideFadeIn {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modalHeader,
.modalFooter {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalBody {
    padding: 20px;
    line-height: 1.4;
    color: #555;

    /* NEW: Force scroll if content too tall */
    max-height: 400px;
    /* or 500px, or any suitable height */
    overflow-y: auto;
}

/* For a smoother scrollbar on some browsers, you can also style it: */
.modalBody::-webkit-scrollbar {
    width: 8px;
}

.modalBody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modalBody::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
}

.modalBody::-webkit-scrollbar-thumb:hover {
    background: #666;
}

.modalTitle {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: #333;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #999;
    cursor: pointer;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #666;
}

.modalFooterButtons {
    display: flex;
    gap: 12px;
}

.actionButton,
.cancelButton {
    border: none;
    border-radius: 6px;
    padding: 8px 14px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}

.actionButton {
    background-color: #2e7d32;
    color: #fff;
}

.actionButton:hover {
    background-color: #1b5e20;
}

.cancelButton {
    background-color: #999;
    color: #fff;
}

.cancelButton:hover {
    background-color: #777;
}

.modalDescription {
    font-size: 0.95rem;
    color: #444;
    margin-bottom: 1rem;
    line-height: 1.5;
}

/* Each conflict item displayed as a card-like section */
.conflictItem {
    background: #f9fafc;
    /* light background for contrast */
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px var(--shadow-light);
}

.conflictItemHeader {
    margin-bottom: 8px;
}

.conflictFieldName {
    color: var(--accent-color);
    font-size: 1.05rem;
}

.conflictItemBody {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* The conflict message text */
.conflictMessage {
    font-size: 0.9rem;
    color: #555;
}

/* Container for label + select */
.conflictAction {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* The label for the select */
.conflictLabel {
    font-weight: 600;
    color: #333;
}

/* Style for the select dropdown */
.conflictSelect {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 0.9rem;
    background: #fff;
    color: #333;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

.conflictSelect:hover,
.conflictSelect:focus {
    border-color: var(--accent-color);
}

.autofillBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    border-top: 1px solid #e0e0e0;
    padding: 12px 24px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.autofillContent {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.autofillActions {
    display: flex;
    gap: 12px;
    align-items: center;
}

.autofillButton {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.autofillButton:hover {
    background: #45a049;
}

.autofillButton:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.dismissButton {
    background: transparent;
    border: none;
    color: #666;
    font-size: 24px;
    cursor: pointer;
    padding: 0 8px;
    line-height: 1;
}

.dismissButton:hover {
    color: #333;
}

.smallLoader {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-left: 8px;
    vertical-align: middle;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}