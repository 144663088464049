.fieldPreview {
    margin-bottom: 10px;
}

.labelWrapper {
  position: relative; /* Allow positioning of the star relative to the label wrapper */
  width: 100%; /* Full width for the label wrapper */
}

.labelContainer {
  display: inline-block; /* Allow the label to wrap around the star */

  position: relative;
  width: 100%; /* Full width for the label */
}

.fieldLabel {
  width: 90%; /* Full width for the textarea */
  background: transparent;
  border: none;
  resize: none;
  color: #565555;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  white-space: pre-wrap;
  font-family: 'montserrat', sans-serif;
  font-size: 15px;
  overflow-y: none;

}

.requiredStar {
  position: relative;
  top: 6px;
  color: red;
  font-size: 16px;
  font-weight: 600;
}

.helpText {
  margin-top: 5px;
  font-size: 11px;
  border: none;
  background: transparent;
  resize: none;
  overflow: hidden;
  color: #333;
  cursor: pointer;
  outline: none;
  width: 90%;
  white-space: pre-wrap; 
  overflow-y: auto;
}


  
.helpText:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none;
}

.fieldLabel.required {
  border-color: red;
}

/* Text field styles */
.textField{
  width: 95%;
}

.textfieldInput {
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  font-family: 'montserrat', sans-serif;
  padding: 9px;

}

.textareaInput {
  width: 95%;
  padding: 9px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  font-family: 'montserrat', sans-serif;
  resize: vertical;
  min-height: 100px;
}
  

/* Checkbox field styles */
.checkboxContainer {
  display: flex;
  align-items: center; /* Aligns items vertically */
  gap: 8px;
}


.checkboxFieldLabel{
  background: transparent;
  border: none;
  resize: auto;
  overflow: hidden;

}

.requiredStarCheckBox {
  position: relative;
  top: 2px;
  margin-left: 10px;
  color: red;
  font-size: 16px;
}

.customRichTextEditor {
  width: 80%; /* Adjust this value to reduce the editor width */
  max-width: 500px; /* Optionally set a max width */
  min-width: 200px; /* Optionally set a min width */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 7px;
}

.labelPreview {
  flex: 1;
  font-size: 15px;
  font-family: inherit;
  color: #333;
  font-weight: 500;
  line-height: 0.5; 
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove padding */
  display: flex;
  align-items: center; /* Center the content vertically */
}

/* Spinner field (dropdown) styles */
.spinnerField {
  width: 95%;
  padding: 5px;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
  display: flex;
  flex-direction: column;
}

.spinnerInput {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  width: 95%;
}

.choicesEditor {
  width: 95%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  resize: vertical;
}


/* radio button styles */
/* Radio Buttons Group */


/* File Upload */
.fileUpload {
  margin: 10px 0;
  width: 95%;
}

.dragDropZone {
  border: 2px dashed #ccc;
  padding: 15px;
  text-align: center;
  color: #666;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border-color 0.3s;
}

.dragDropZone.dragOver {
  background-color: #e9f5ff;
  border-color: #007bff;
}

.uploadIcon {
  font-size: 3rem;
  color: #6d00cc;
}

.dragDropText {
  font-size: 1rem;
  color: #333;
}

.dragDropText .browseLink {
  color: #6d00cc;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 600;
}

.allowedFileTypes {
  font-size: 0.85rem;
  color: #888;
  margin-top: 10px;
}

.hiddenInput {
  display: none;
}


/* Choices Controls */
.choicesControls {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.choiceItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.choiceInput {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.choiceInput:focus {
  border-color: #0078d7;
}

.choiceButton {
  background-color: #0078d7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.choiceButton:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.choiceButton:hover:not(:disabled) {
  background-color: #005fa1;
}

.addChoiceButton {
  display: block;
  margin: 1rem auto 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0078d7;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addChoiceButton:hover {
  background-color: #005fa1;
}



/* Number Field Styles */

.numberInput {
  width: 90%;
  padding: 9px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;


  font-family: 'montserrat', sans-serif;
  font-size: 15px;
  
}


/* Date Field Styles */


.dateInputWrapper {
  width: 90%;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.dateInputWrapper:hover {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dateInputWrapper:focus-within {
  border-color: #0056b3;
}

.calendarIcon {
  font-size: 1.5rem;
  color: #007bff;
  margin-right: 10px;
}

.dateInput {
  width: 90%;
  flex: 1;
  font-size: 1rem;
  border: none;
  outline: none;
  background: none;
}


/* multi-select field styles */
