.fieldPreview {
    margin-bottom: 10px;
  }
  
  .labelWrapper {
  position: relative; /* Allow positioning of the star relative to the label wrapper */
  width: 100%; /* Full width for the label wrapper */
  }
  
  .labelContainer {
  display: inline-block; /* Allow the label to wrap around the star */
  
  position: relative;
  width: 100%; /* Full width for the label */
  }
  
  .fieldLabel {
  width: 90%; /* Full width for the textarea */
  background: transparent;
  border: none;
  resize: none;
  color: #565555;
  font-size: 15px;
  font-weight: 600;
  font-family: inherit;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
  white-space: pre-wrap;
  overflow-y: none;
  
  }
  
  .requiredStar {
  position: relative;
  top: 6px;
  color: red;
  font-size: 16px;
  }
  
  .helpText {
  font-size: 11px;
  border: none;
  background: transparent;
  resize: none;
  overflow: hidden;
  color: #333;
  cursor: pointer;
  outline: none;
  width: 90%;
  white-space: pre-wrap; 
  overflow-y: auto;
  margin-bottom: 0px;
  }
  
  
  
  .helpText:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none;
  }
  
  .fieldLabel.required {
  border-color: red;
  }



.checkboxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align checkboxes to the left */
    gap: 8px;
    margin-bottom: 10px;
}
    
    .choicesEditor {
    margin-top: 10px;
}
    
.choiceItem {
    display: flex;
    align-items: left;
    margin-bottom: 5px;
}
    
.choiceInput {
    flex: 1;
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}
    
.deleteChoiceButton {
    margin-left: 10px;
    padding: 5px;
    font-size: 0.9rem;
    color: white;
    background-color: red;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
    
.addChoiceButton {
    margin-top: 10px;
    padding: 5px 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
    
.addChoiceButton:hover {
    background: #0056b3;
}
    
.selectionControls {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}
    
.selectionControl label {
    font-size: 0.9rem;
    color: #666;
}
    
.selectionControl input {
    width: 60px;
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}
    
    
.separator {
    margin-top: 10px;
    margin-bottom: 15px;
    border-top: 1px dashed #ddd;
    width: 100%; 
}

.choicesEditor {
    width: 95%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    resize: vertical;
  }