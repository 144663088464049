/* -------------------------------------
   Container
------------------------------------- */
.card {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  /* Add transitions for smooth animations */
  transition:
    transform 0.3s ease,
    box-shadow 0.4s ease,
    background-color 0.4s ease;
}

.card:hover {
  transform: translateY(-8px) scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
  background-color: #f9f3ff;
}

/* Entire card clickable except Delete button */
.cardLink {
  display: block;
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
}

/* -------------------------------------
   Image
------------------------------------- */
.cardImageWrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #f1f1f1;
  overflow: hidden;
  position: relative;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

/* More pronounced zoom on hover */
.card:hover .cardImage {
  transform: scale(1.1);
}

/* -------------------------------------
   Body
------------------------------------- */
.cardBody {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px;
  align-items: flex-start;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.cardTitle {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
  transition: color 0.3s ease;
}

/* Brighter color change on hover */
.card:hover .cardTitle {
  color: #ab47bc;
}

.cardDescription {
  margin: 0;
  font-size: 0.875rem;
  color: #444;
  line-height: 1.5;
  max-height: 4.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Category & Status pills */
.categoryPill,
.statusPill {
  display: inline-block;
  white-space: nowrap;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 8px;
}

.categoryPill {
  background-color: #ebe7f9;
  color: #5b4f8b;
}

.statusPill {
  background-color: #4caf50;
  color: #ffffff;
}

.statusActive {
  background-color: #4caf50;
}

.statusPending {
  background-color: #ffb300;
}

.statusFailed {
  background-color: #c62828;
}

.statusCompleted {
  background-color: #0277bd;
}

/* -------------------------------------
   Footer
------------------------------------- */
.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border-top: 1px solid #eee;
  background-color: #fafafa;
}

.deleteButton {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 500;
  background-color: #e53935;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.deleteButton:hover {
  background-color: #d32f2f;
  transform: scale(1.03);
}

/* -------------------------------------
   Progress ring
------------------------------------- */
.progressWrapper {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.creativeProgressCircle {
  width: 100%;
  height: auto;
  transform: rotate(-90deg);
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.2));
}

.creativeCircleBg {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 10;
}

.creativeCircleFill {
  fill: none;
  stroke: url(#ProgressGradient);
  stroke-width: 10;
  stroke-dasharray: 283;
  transition: stroke-dashoffset 0.6s ease, stroke 0.6s ease;
}

.creativeProgressText {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: #6a1b9a;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.rtl {
  direction: rtl;
}

.card.rtl .progressWrapper {
  margin-left: 0;
}