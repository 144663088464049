/* ----- Basic Animations for Fade/Scale ----- */
@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* ----- Backdrop & Container ----- */
  .backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0,0,0,0.45);
    z-index: 9999;
    animation: fadeIn 0.2s ease-out;
  }
  
  .modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 480px;
    max-width: 95%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0,0,0,0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
  }
  
  /* ----- Header (Purple Bar) ----- */
  .modalHeader {
    background-color: #6d00cc;
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modalTitle {
    margin: 0;
    font-size: 1.25rem;
    color: #fff;
    font-weight: 600;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #fff;
    cursor: pointer;
  }
  .closeButton:hover {
    color: #ddd;
  }
  
  /* ----- Body ----- */
  .modalBody {
    padding: 1rem 1.25rem;
  }
  
  /* ----- Option Cards Wrapper ----- */
  .optionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  /* Base card style */
  .optionCard {
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    gap: 0.75rem;
    align-items: center; /* changed to center so icon & text line up */
    cursor: pointer;
    transition: border-color 0.2s;
    position: relative;
  }
  .optionCard:hover {
    border-color: #6d00cc;
  }
  
  /* Hide the default radio visually */
  .radioInput {
    display: none;
  }
  
  /* Icon Circle
     - unselected: light purple background + brand purple icon
     - selected: brand purple background + white icon
  */
  .iconCircle {
    border-radius: 9999px;
    padding: 0.75rem;
    background-color: #f3e6ff; /* light purple */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s;
  }
  
  /* Unselected icon color */
  .optionCardIcon {
    font-size: 1.4rem;
    color: #6d00cc;
  }
  
  /* Selected states */
  .iconCircleSelected {
    background-color: #6d00cc; /* darker purple when selected */
  }
  .iconSelected {
    color: #fff !important;
  }
  
  /* Card texts */
  .optionCardTexts {
    display: flex;
    flex-direction: column;
  }
  
  .optionCardTitle {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
  }
  
  .optionCardDesc {
    margin: 4px 0 0;
    font-size: 0.9rem;
    line-height: 1.4;
    color: #666;
  }
  
  /* The "selected" style: purple border & background highlight */
  .optionSelected {
    background-color: #f3e6ff; /* light purple fill */
    border: 2px solid #6d00cc;
  }
  
  /* OptionSelected text color override */
  .optionSelected .optionCardTitle,
  .optionSelected .optionCardDesc {
    color: #6d00cc;
  }
  
  /* Check mark in top-right corner of the selected card */
  .checkMark {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #6d00cc;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* ----- Notify Card ----- */
  .notifyCard {
    background-color: #f9f4ff; /* a faint purple background */
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .notifyLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .notifyIcon {
    font-size: 1.3rem;
    color: #6d00cc;
  }
  
  .notifyText {
    margin: 0;
    font-size: 0.95rem;
    color: #333;
    font-weight: 500;
  }
  
  /* ----- Toggle Switch ----- */
  .customToggle {
    position: relative;
    width: 46px;
    height: 24px;
    background-color: #ddd;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggleHandle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 0.3s;
  }
  
  .on {
    background-color: #6d00cc !important;
  }
  .on .toggleHandle {
    left: 24px;
  }
  
  /* ----- Footer Buttons ----- */
  .modalFooter {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    border-top: 1px solid #eee;
  }
  
  .cancelButton {
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;
    padding: 0.45rem 1rem;
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
    color: #333;
  }
  .cancelButton:hover {
    background-color: #ddd;
  }
  
  .publishButton {
    background-color: #6d00cc;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.45rem 1rem;
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
  }
  .publishButton:hover {
    background-color: #5900a5;
  }
  