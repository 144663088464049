.taskBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 60px;
    border: 2px solid #e5e7eb;
    border-radius: 25px;
    cursor: move;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
                0px 1px 2px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease;
  }
  
  .taskBox:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
                0px 2px 4px rgba(0, 0, 0, 0.06);
    transform: translateY(-1px);
  }
  
  .taskTitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
    text-align: center;
    line-height: 1.25;
  }
  