/* ============================= */
/*        MAIN LAYOUT           */
/* ============================= */

.outerContainer {
  display: flex;
  width: 100%;
  height: 80vh;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

/* Left panel for dependencies */
.leftPanel {
  width: 40%;
  min-width: 320px;
  background: #fff;
  margin: 0 10px 10px 10px;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: auto;
  border: 2px solid #6d00cc;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

/* Title above the dependencies table */
.depHeader {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #6d00cc;
}

/* ============================= */
/*    DEPENDENCIES TABLE CSS    */
/* ============================= */

/* A wrapper that might allow horizontal scrolling if too wide */
.tableWrapper {
  overflow-x: auto;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 16px;
}

/* Actual table */
.depTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  min-width: 420px;
}

.depTable thead th {
  background: #fafafa;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  white-space: nowrap;
}

.depTable tbody tr {
  transition: background 0.2s, box-shadow 0.2s;
}
.depTable tbody tr:hover {
  background: #f8f8ff; /* slight hover highlight */
}

/* Each cell */
.depTable tbody td {
  padding: 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

/* dot + label for tasks */
.taskWithDot {
  display: flex;
  align-items: center;
  gap: 6px;
}

.taskDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex-shrink: 0;
}

/* Type “pill” => background, icon, select, etc. */
.typePill {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 20px;
  background: #eee;
  transition: transform 0.2s;
}
.typePill:hover {
  transform: scale(1.02);
}

/* The select inside the pill */
.typeSelect {
  appearance: none;
  border: none;
  background: transparent;
  font-size: 0.85rem;
  cursor: pointer;
  color: inherit;
  padding-right: 16px; /* space for arrow if needed */
}
.typeSelect:focus {
  outline: none;
}

/* Remove X button */
.removeBtn {
  background: none;
  border: none;
  cursor: pointer;
  color: #e00;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  transition: background 0.2s;
}
.removeBtn:hover {
  background: #ffe5e5;
}

/* “Add Dependency” => full width, nice gradient, etc. */
.addDepBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  background: linear-gradient(45deg, #6d00cc, #ab50ff);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 0.95rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}
.addDepBtn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* ============================= */
/*      RIGHT PANEL (Flow)       */
/* ============================= */

.rightPanel {
  width: 60%;
  position: relative;
  background: #f3f3f3;
  border: 2px solid #6d00cc;
  border-radius: 12px;
  margin: 0 10px 10px 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

/* Legend pinned top-left */
.legendBox {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  z-index: 10;
  font-size: 0.9rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
}

.legendColor {
  display: inline-block;
  width: 20px;
  height: 5px;
  border-radius: 3px;
}

/* “Fit to View” button top-right */
.fitBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(45deg, #6d00cc, #aa44ff);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  transition: transform 0.2s;
}
.fitBtn:hover {
  transform: scale(1.05);
}

/* ============================= */
/*  Center-Screen Modal (Edge)   */
/* ============================= */

.centerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s ease-out forwards;
}

.centerModal {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  width: 360px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  transform: translateY(-20px);
  animation: slideDown 0.3s ease-out forwards;
}

.centerChoices {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.centerChoice {
  padding: 12px;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease;
}
.centerChoice:hover {
  transform: scale(1.03);
}

/* Cancel button in that center modal */
.centerCancel {
  background: #6d00cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 14px;
  cursor: pointer;
  transition: background 0.2s;
}
.centerCancel:hover {
  background: #5c00a6;
}

/* ============================= */
/*   The “AddDependencyModal”    */
/* ============================= */

.addModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s ease-out forwards;
}

.addModalContent {
  background: #fff;
  width: 420px;
  max-width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 14px rgba(0,0,0,0.15);
  overflow: hidden;
  position: relative;
  transform: translateY(-20px);
  animation: slideDown 0.3s ease-out forwards;
}

/* The banner with a gradient */
.modalBanner {
  background: linear-gradient(45deg, #6d00cc, #b166ff);
  color: #fff;
  padding: 18px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  position: relative;
}

/* Body with the fields */
.modalBody {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.fieldRow {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.fieldRow label {
  font-size: 0.9rem;
  font-weight: 500;
}

.fieldRow select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Footer actions */
.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 8px 16px 16px;
}

/* “Cancel” button */
.flatBtn {
  background: #ccc;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s;
}
.flatBtn:hover {
  background: #bbb;
}

/* “Add” button */
.addBtn {
  background: #6d00cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s;
}
.addBtn:hover {
  background: #5c00a6;
}

/* ============================= */
/*       Simple Animations       */
/* ============================= */

@keyframes fadeIn {
  from { background: rgba(0,0,0,0); }
  to   { background: rgba(0,0,0,0.5); }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
