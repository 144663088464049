:root {
    --primary-color: #4A90E2;
    --secondary-color: #34495E;
    --accent-color: #3A7BD5;
    --background-color: #FFFFFF;
    --input-background: #F7F7F8;
    --border-color: #DDDDDD;
    --error-color: #E74C3C;
    --success-color: #2ECC71;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --transition-speed: 0.3s;
    --shadow-light: rgba(0, 0, 0, 0.05);
    --info-bg: #EFF5FA;
    --info-border: #B4D0F7;
}

.formItemContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: var(--font-family);
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #eee;
    box-shadow: 0 2px 8px var(--shadow-light);
    transition: box-shadow var(--transition-speed), border-color var(--transition-speed);
}

.formItemContainer:hover {
    box-shadow: 0 4px 12px var(--shadow-light);
}

.labelRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.formLabel {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--secondary-color);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 4px;
}

.required {
    color: var(--error-color);
    font-size: 1.1rem;
}

.helpBox {
    display: flex;
    align-items: center;
    gap: 6px;
    background: var(--info-bg);
    border: 1px solid var(--info-border);
    border-radius: 8px;
    padding: 6px 10px;
    font-size: 0.95rem;
    color: var(--secondary-color);
}

.helpIcon {
    color: var(--accent-color);
}

.formInput,
.formSelect,
.multiSelect,
.formTextarea {
    padding: 14px 16px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
    font-size: 1rem;
    color: var(--secondary-color);
    width: 100%;
    box-sizing: border-box;
}

.formInput:focus,
.formSelect:focus,
.multiSelect:focus,
.formTextarea:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.2);
    outline: none;
}

.formTextarea {
    resize: vertical;
}

/* Custom Spinners */
.customSpinner,
.customSpinnerSmall {
    border: 3px solid #f3f3f3;
    border-top: 3px solid var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
    position: absolute;
    right: 16px;
}

.customSpinner {
    width: 20px;
    height: 20px;
}

.customSpinnerSmall {
    width: 16px;
    height: 16px;
    right: 12px;
}

@keyframes spin {
    100% { transform: rotate(360deg); }
}

/* Success & Error Icons */
.successIcon,
.errorIcon {
    position: absolute;
    right: 16px;
    font-size: 1.2rem;
}

.successIconSmall,
.errorIconSmall {
    position: absolute;
    right: 12px;
    font-size: 1rem;
}

.successIcon {
    color: var(--success-color);
    animation: fadeIn 0.5s ease-in-out;
}

.successIconSmall {
    color: var(--success-color);
    animation: fadeIn 0.5s ease-in-out;
}

.errorIcon {
    color: var(--error-color);
    animation: shake 0.3s ease-in-out;
}

.errorIconSmall {
    color: var(--error-color);
    animation: shake 0.3s ease-in-out;
}

/* Animations */
@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    75% { transform: translateX(4px); }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Single Checkbox */
.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
}

.checkbox {
    width: 20px;
    height: 20px;
    accent-color: var(--accent-color);
    cursor: pointer;
    transition: transform var(--transition-speed);
}

.checkbox:hover {
    transform: scale(1.1);
}

.checkboxLabel {
    font-size: 1rem;
    color: var(--secondary-color);
    cursor: pointer;
}

/* Radios */
.radioGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
}

.radioLabel {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 8px;
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed);
}

.radioLabel:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px var(--shadow-light);
}

.radioInput {
    width: 20px;
    height: 20px;
    accent-color: var(--accent-color);
    cursor: pointer;
}

.radioText {
    font-size: 1rem;
    color: var(--secondary-color);
}

/* Multi Select Buttons */
.multiSelectGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    min-height: 40px;
    padding-right: 30px;
}

.multiSelectOption {
    background: #f8f8f8;
    border: 1px solid #ccc;
    color: #333;
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed), border-color var(--transition-speed);
    user-select: none;
}

.multiSelectOption:hover {
    background-color: #eee;
    border-color: var(--accent-color);
}

.multiSelectOptionSelected {
    background: var(--accent-color);
    color: #fff;
    border-color: var(--accent-color);
    box-shadow: 0 2px 5px var(--shadow-light);
}

/* Multi Select Checkboxes */
.multiCheckboxGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding-right: 30px;
}

.multiCheckboxLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    transition: background-color var(--transition-speed);
}

.multiCheckboxLabel:hover {
    background: #f1f1f1;
}

.multiCheckboxInput {
    width: 18px;
    height: 18px;
    accent-color: var(--accent-color);
    cursor: pointer;
}

.multiCheckboxText {
    font-size: 1rem;
    color: var(--secondary-color);
}

/* Range */
.rangeContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rangeInput {
    width: 100%;
    cursor: pointer;
    accent-color: var(--accent-color);
}

.rangeValue {
    font-size: 1rem;
    color: var(--secondary-color);
    text-align: center;
    font-weight: 500;
}

/* File Upload */
.fileUploadWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.fileUploadLabel {
    padding: 14px 20px;
    border: 2px dashed var(--accent-color);
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color var(--transition-speed), border-color var(--transition-speed);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.fileUploadLabel:hover {
    background-color: #e8e8e8;
    border-color: var(--secondary-color);
}

.fileInput {
    display: none;
}

.fileHelp {
    font-size: 0.9rem;
    color: var(--secondary-color);
}

/* Textarea */
.textareaWrapper {
    position: relative;
}

/* Additional text notes */
.helpText {
    font-size: 0.95rem;
    color: var(--secondary-color);
    background-color: #F8F9FA;
    padding: 10px 14px;
    border-radius: 8px;
    margin-top: -4px;
}

.fieldHint {
    font-size: 0.85rem;
    color: #6C757D;
    font-style: italic;
    margin-top: -4px;
    padding-left: 4px;
}

/* Information field */
.informationField {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1rem;
    color: var(--secondary-color);
    background: #F8F9FA;
    padding: 14px 20px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.infoIcon {
    color: var(--primary-color);
    font-size: 1.2rem;
}

/* Unsupported Field */
.unsupportedField {
    font-size: 1rem;
    color: var(--error-color);
    background-color: #FDECEA;
    padding: 10px 14px;
    border-radius: 8px;
}

/* Error Input Border */
.inputError {
    border-color: var(--error-color) !important;
}
