/*******************************************************
 * Card Container
 *******************************************************/
.browseCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.browseCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
}

/*******************************************************
   * Header (Image)
   *******************************************************/
.browseCardHeader {
  width: 100%;
  height: 160px;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.browseCardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.browseCardHeader:hover .browseCardImage {
  transform: scale(1.05);
}

/*******************************************************
   * Body
   *******************************************************/
.browseCardBody {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 6px;
  align-items: flex-start;
}

.browseCardTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.browseCardUserInfo {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #555;
}

.userIcon {
  color: #666;
}

.userCountText {
  font-weight: 500;
  color: #444;
}

.browseCardCategory {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  background-color: #ebe7f9;
  color: #5b4f8b;
  padding: 3px 8px;
  border-radius: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.browseCardDescription {
  font-size: 13px;
  color: #444;
  line-height: 1.4;
  margin: 0;
  max-height: 4.2em;
  /* ~3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
}

/*******************************************************
   * Footer (Buttons)
   *******************************************************/
.browseCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid #eee;
  background-color: #fafafa;
  margin-top: auto;

}

.viewButton {
  background: none;
  border: 2px solid #663399;
  border-radius: 6px;
  color: #663399;
  padding: 6px 12px;
  font-size: 13px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.2s, color 0.2s;
}

.viewButton:hover {
  background-color: #f5eefc;
}

.addButton {
  background-color: #663399;
  color: #fff;
  border: 2px solid #663399;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.addButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.addButton:hover:not(:disabled) {
  background-color: #5a2787;
  transform: scale(1.02);
}

.rtl {
  direction: rtl;
}

