/* Container */
.notifWrapper {
  max-width: 800px;
  margin: 2rem auto;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.1);
  font-family: 'Inter', sans-serif;
  animation: fadeInUp 0.5s forwards ease; /* Overall container enters with fade/slide */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Header row */
.notifHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.titleSection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bellContainer {
  position: relative;
  display: inline-block;
}

.bellIcon {
  font-size: 1.5rem;
  color: #000;
}

.badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f44336; /* red */
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 50%;
  padding: 3px 6px;
}

.pageTitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  color: #000;
}

/* Icons on the right (filter, settings) */
.iconBar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.headerIcon {
  font-size: 1.2rem;
  color: #444;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;
}

.headerIcon:hover {
  color: #000;
  transform: scale(1.1);
}

/* Tab row */
.tabRow {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.tabButton {
  background: none;
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #555;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  position: relative;
}
.tabButton:hover {
  color: #000;
  background-color: #f9f9f9;
}

.activeTab {
  border-bottom: 3px solid #2196f3;
  color: #000;
}

/* Notification list area */
.notifList {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.emptyState {
  text-align: center;
  font-size: 0.95rem;
  color: #777;
}

/* Notification card */
.card {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 1rem;
  border: 1px solid #eee;
  animation: cardEnter 0.4s ease forwards;
  position: relative;
}
@keyframes cardEnter {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.unread {
  border-left: 4px solid #2196f3;
}
.read {
  border-left: 4px solid #bbb;
}

/* Card top: checkbox + label + time */
.cardHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkBox {
  font-size: 1.1rem;
  color: #999;
}

.checkIcon {
  cursor: pointer;
  transition: color 0.2s;
}
.checkIcon:hover {
  color: #555;
}

/* The label (like "Workflow Update" / "Deadline Approach") */
.labelChip {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
}

.timeStamp {
  margin-left: auto;
  font-size: 0.8rem;
  color: #666;
}

/* Card body */
.cardBody p {
  margin: 0;
  font-size: 0.9rem;
  color: #444;
}

/* Card bottom - Mark as Read button (only if unread) */
.cardFooter {
  text-align: right;
  margin-top: 0.5rem;
}

.markAsReadBtn {
  background-color: #eeeeee;
  border: none;
  font-size: 0.8rem;
  color: #333;
  font-weight: 600;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  transition: background-color 0.2s, transform 0.2s;
}
.markAsReadBtn:hover {
  background-color: #ddd;
  transform: translateY(-1px);
}
.eyeIcon {
  font-size: 1rem;
}

.settingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9faff;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}
.settingCard:hover {
  background-color: #eff3ff;
  transform: translateY(-2px);
}

.leftSection {
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
}

.settingIcon {
  font-size: 1.4rem;
  color: #6d00cc; /* Light blue color */
  flex-shrink: 0; /* prevent icon from shrinking in smaller screens */
}

.settingName {
  font-size: 1rem;
  margin: 0;
  color: #333;
  font-weight: 600;
}

.settingDescription {
  margin: 2px 0 0;
  font-size: 0.85rem;
  color: #777;
}

/* Each "toggleSwitch" container */
.toggleSwitch {
  width: 44px;
  height: 24px;
  background-color: #ccc;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggleOn {
  background-color: #4caf50; /* green color when ON */
}
.toggleKnob {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 9999px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}
.toggleOn .toggleKnob {
  left: 22px; /* move knob to the right when ON */
}

/* For the horizontal line */
.divider {
  border: none;
  border-top: 1px solid #eee;
  margin: 1.5rem 0;
}

/* field group for "Notification Method" etc. */
.fieldGroup {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}

.fieldLabel {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-left: 10px;
}

.methodSelect {
  padding: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  margin-left: 25px;
}

/* For the Sound toggle row (optional styling) */
.soundSetting {
  background-color: #fff9f9; /* slightly pink background */
}
.soundSetting:hover {
  background-color: #ffefef;
}


.settingsWrapper {
  max-width: 600px;
  margin: 2rem auto;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08);
}

.settingsTitle {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 600;
}


/* The top bar with back button + title */
.topBar {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Back button styling */
.backButton {
  background: none;
  border: none;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s, transform 0.2s;
  margin-right: 0.5rem;
}

.backButton:hover {
  color: #000;
  transform: translateX(-2px);
}

.backIcon {
  font-size: 1.4rem;
}

/* Center the Save button container */
.saveBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem; /* some extra spacing before the button */
}

.saveButton {
  font-size: 1rem;       /* Larger font */
  padding: 0.8rem 1.6rem;/* More padding */
  border-color: #724ebf;
  background: radial-gradient(100.03% 140.18% at 0% 85.53%, #ff00ff 0%, #724ebf 95.31%);
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.saveButton:hover {
  transform: translateY(-2px);
}

/* Loading message styling (if you want it) */
.loadingMessage {
  text-align: center;
  font-size: 1rem;
  color: #444;
  margin: 2rem 0;
}

/* The multi-select bar that appears if one or more checkboxes are selected */
.multiSelectBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1; /* Light grey background */
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
}

/* For the "Mark as Read" button in the multi-select bar */
.markSelectedBtn {
  background-color: #6d00cc; /* Purple color from your project */
  border: none;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}
.markSelectedBtn:hover {
  background-color: #5800a3; /* Slightly darker purple on hover */
  transform: translateY(-1px);
}

/* If you prefer #724ebf, just swap the color: 
.markSelectedBtn {
  background-color: #724ebf;
  ...
}
.markSelectedBtn:hover {
  background-color: #5e3d93;
}
*/

/* Example of changing the checkIcon color to purple on hover */
.checkIcon:hover {
  color: #6d00cc;
}
