/***************************************************
 * Outer Container
 ***************************************************/
.browseDashboardContainer {
  display: flex;
  margin-bottom: 40px;
  padding-left: 60px;
}

/***************************************************
 * Content Area
 ***************************************************/
.browseDashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  position: relative;
}

/***************************************************
 * Header
 ***************************************************/
.browseDashboardHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #e3e4e8;
  margin: 0 -2rem 0rem;
  padding: 20px 2rem 10px 2rem;
  align-items: flex-start;
}

/***************************************************
 * Grid Section
 ***************************************************/
.browseWorkflowGridSection {
  position: relative;
  min-height: 200px;
  margin-bottom: 2rem;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 20px;
  zoom: 0.7;
}

/***************************************************
 * Loading State
 ***************************************************/
.browseLoadingBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.browseSpinner {
  width: 35px;
  height: 35px;
  border: 5px solid #eee;
  border-top: 5px solid #663399;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.browseLoadingText {
  color: #555;
  font-size: 1rem;
}

/***************************************************
 * Workflow Grid
 ***************************************************/
.browseWorkflowGrid {
  /* Key: responsive grid with min width to prevent overlap on zoom */
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  position: relative;
  margin-top: 10px;
  box-sizing: border-box;
}

/***************************************************
 * Empty State
 ***************************************************/
.browseEmptyBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.browseEmptyIcon {
  width: 60px;
  margin-bottom: 10px;
  opacity: 0.8;
}

.browseEmptyText {
  font-size: 1.2rem;
  color: #777;
}

.rtl {
  direction: rtl;
}

.browseDashboardContainer.rtl {
  padding-right: 70px;
  padding-left: 0px;
}