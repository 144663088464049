:root {
  --accent-color: #2A4A7B;
  --success-color: #28a745;
  --error-color: #d9534f;
  --info-color: #17a2b8;
  --text-dark: #333;
  --text-medium: #555;
  --text-light: #f8f8f8;
  --bg-light: #fff;
  --bg-dark: #3a3a3a;
  --font-primary: 'Inter', sans-serif;
  --transition-speed: 0.3s;
}

/* MAIN WRAPPER */
.viewWorkflowContainer {
  padding: 2rem 2rem;
  background: #f8f8f8;
  min-height: 100vh;
  font-family: var(--font-primary);
  padding-left: 100px;
}

/***** TOP BAR *****/
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pageTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #724ebf;
  margin: 0;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.searchInput {
  width: 240px;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  font-size: 0.9rem;
  transition: border-color var(--transition-speed);
}

.searchInput:focus {
  border-color: var(--accent-color);
}

.settingsBtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #999;
  transition: color 0.2s;
}

.settingsBtn:hover {
  color: var(--text-dark);
}

/***** TITLE *****/
.title {
  font-size: 2rem;
  font-weight: 700;
  color: #724ebf;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.title::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #b55dcd 0%, #724ebf 100%);
  margin: 0.5rem auto 0 auto;
  border-radius: 2px;
}

/***** STATS ROW *****/
.statsRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 5%;
  margin-bottom: 2rem;
}

.statCard {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.statIcon {
  font-size: 1.4rem;
  margin-right: 0.5rem;
  color: #724ebf;
}

.statNumber {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0;
  color: var(--accent-color);
}

.statLabel {
  font-size: 0.8rem;
  color: #666;
  margin: 0;
}

.statChangePositive {
  color: var(--success-color);
  font-size: 0.75rem;
}

.statChangeNegative {
  color: var(--error-color);
  font-size: 0.75rem;
}

/***** SECTION TITLE *****/
.sectionTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: #724ebf;
  margin: 1rem 0 1rem 0;
}

/***** USER CARD GRID *****/
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 1.5rem;
  zoom : 0.9;
}

/***** USER CARD *****/
.userCard {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  transition: box-shadow var(--transition-speed);
}

.userCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.userNameStatusImg {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.userNameStatus {
  display: flex;
  flex-direction: column;
}

.userCardHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.metaIcon {
  font-size: 0.85rem;
  margin-right: 4px;
  color: #999;
}

.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-dark);
  margin: 0;
  margin-bottom: 5px;
}

.statusActive {
  display: block;
  font-size: 0.8rem;
  color: var(--success-color);
  margin-left: 2px;
}

.statusInactive {
  display: block;
  font-size: 0.8rem;
  color: #999;
  margin-left: 2px;
}

/* 3-dots menu */
.cardMenu {
  position: relative;
}

.menuButton {
  background: none;
  border: none;
  font-size: 1.25rem;
  color: #999;
  cursor: pointer;
  padding: 0;
}

.menuButton:hover {
  color: #333;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: none;
  flex-direction: column;
  min-width: 120px;
}

.cardMenu:hover .dropdownMenu {
  display: flex;
}

.dropdownMenu button {
  background: none;
  border: none;
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  cursor: pointer;
  color: #333;
  transition: background 0.2s;
}

.dropdownMenu button:hover {
  background: #f1f1f1;
}

/* PROGRESS SECTION */
.progressSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 10px;
}

.progressLabel {
  font-size: 0.8rem;
  color: #666;
  font-weight: 500;
}

.progressValue {
  font-size: 0.85rem;
  font-weight: 600;
  text-align: right;
}

.progressBar {
  width: 100%;
  height: 6px;
  background: #eee;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.progressFill {
  height: 100%;
  background: #724ebf;
  transition: width 0.3s ease;
}

/* META (last active, tasks done) */
.userMeta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #999;
  padding-top: 10px;
}

.lastActive {
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
}

.taskCount {
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
}

/* OPEN TASKS BUTTON */
.openTasksBtn {
  background: linear-gradient(90deg, #724ebf 0%, #b55dcd 100%);
  color: #fff;
  padding: 0.75rem 1.7rem;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  /* make it uppercase again */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.openTasksBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* ANIMATIONS */
@keyframes containerSlideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade for entire container */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rtl{
  direction: rtl;
}

.viewWorkflowContainer.rtl{
  padding-left: 20px;
  padding-right: 100px;
}