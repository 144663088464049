/* Original Navbar */
.originalNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(90deg, #ffffff, #f2f2f2); /* new or updated: subtle gradient */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  transition: top 0.4s ease; /* slightly faster transition */
}

.show {
  top: 0; /* Visible at the top */
}

.hide {
  top: -63px; /* Moves navbar out of view smoothly */
}

/* Fixed Navbar for Tabs */
.fixedNavbar {
  background-color: #f9f9fa; /* light background to differentiate from main navbar */
  position: fixed;
  top: 60px; /* Start below the original navbar */
  left: 0;
  width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  padding: 6px 15px; /* new or updated: more horizontal space */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: top 0.4s ease; 
  font-family: "Inter", sans-serif; /* new or updated: a modern font if you have it */
}

.fixedNavbar.topPosition {
  top: 0; /* Move to the top when the original navbar hides */
}

.logoSection {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 25px;
  margin-right: 10px;
}

.logoText {
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
  position: relative;
}

.logoText::after {
  content: '';
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  background-color: #ccc;
}

.workflowBuilderText {
  font-size: 16px;
  font-weight: normal;
  color: #555;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  cursor: default;
}

.workflowBuilderText i {
  font-size: 14px; /* new or updated */
  color: #555;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Buttons */
.navButton {
  background-color: #f4f4f4;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-right: 15px;
  display: flex; /* new or updated */
  align-items: center; /* new or updated */
  gap: 6px; /* new or updated: spacing between icon & text */
}

.navButton:hover {
  background-color: #e5e5e5;
}

/* The Publish Button */
.publishButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #6d00cc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px; /* new or updated */
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(109, 0, 204, 0.2);
}

.publishButton i {
  font-size: 14px;
}

.publishButton:hover {
  background-color: #5b00ad;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(109, 0, 204, 0.3);
}

.publishButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(109, 0, 204, 0.2);
}

/* NavTab Buttons in the fixed navbar */
.navTab {
  padding: 8px 20px; /* Adjust padding for the buttons */
  background-color: transparent;
  border: none;
  font-size: 16px; 
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  color: #333; /* text color */
  display: flex; /* new or updated */
  align-items: center; /* new or updated */
  gap: 8px; /* new or updated: spacing between icon & label */
  border-radius: 8px; /* new or updated */
  transition: background-color 0.3s, color 0.3s;
  margin: 0 4px; /* spacing between tabs */
}

.navTab i {
  font-size: 15px; /* icon size */
}

.navTab:hover {
  background-color: #e0e0e0;
  color: #000; /* dark text on hover */
}

/* Active Tab State */
.activeTab {
  background-color: #6d00cc;
  color: #ffffff !important; /* ensure text is white */
  box-shadow: 0 2px 4px rgba(109, 0, 204, 0.2);
  transition: background-color 0.3s, color 0.3s;
}
