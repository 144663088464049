/* File: SignatureInput.module.css */
.signatureContainer {
    border: 1px solid #e1e5ea;
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-bottom: 16px;
    position: relative;
    box-shadow: 0 2px 5px rgba(0,0,0,0.06);
  }
  
  /* Tabs Header */
  .tabHeader {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 12px;
  }
  .tabButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 0.95rem;
    color: #666;
    transition: background 0.2s;
    position: relative;
  }
  .tabButton:hover {
    background: #f4f4f4;
  }
  .activeTab {
    border-bottom: 3px solid #0984e3;
    color: #0984e3;
    font-weight: 600;
  }
  
  /* Tab Content */
  .tabContent {
    min-height: 100px;
  }
  
  /* Draw Tab */
  .drawTab {
    position: relative;
  }
  
  .signaturePadWrapper {
    position: relative;
    border: 1px solid #ccc;
    height: 160px;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .signatureCanvas {
    width: 100%;
    height: 100%;
    cursor: crosshair;
  }
  /* Overlay if empty */
  .placeholderOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #bbb;
  }
  .penIcon {
    font-size: 1.4rem;
    margin-bottom: 4px;
  }
  .greyLine {
    margin-top: 8px;
    width: 100px;
    border-bottom: 2px dashed #bbb;
  }
  /* Clear button inside the pad */
  .clearButton {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: #f66;
    color: #fff;
    border: none;
    padding: 5px 8px;
    font-size: 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  
  /* Type Tab */
  .typeTab {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .typedSignatureInput {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .fontSelect {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .typedPreview {
    margin-top: 6px;
    border: 1px dashed #ccc;
    background: #fafafa;
    padding: 10px;
    font-size: 2.0rem;
    color: #333;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .typeNote {
    font-size: 0.85rem;
    color: #777;
  }
  
  /* Upload Tab */
  .uploadTab {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .uploadPreview img {
    max-width: 240px;
    max-height: 120px;
    object-fit: contain;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .clearUploadButton {
    background: #f66;
    color: #fff;
    border: none;
    padding: 5px 8px;
    font-size: 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  
  /* Required, help text, status, etc. */
  .requiredMark {
    color: red;
    font-size: 0.9rem;
    margin-left: 4px;
  }
  .helpText {
    margin-top: 6px;
    font-size: 0.85rem;
    color: #666;
  }
  .stateIcons {
    margin-top: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .savingSpinner {
    width: 15px;
    height: 15px;
    border: 2px solid #999;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  .successMark {
    color: green;
    font-size: 0.9rem;
  }
  .errorMark {
    color: red;
    font-size: 0.9rem;
  }
  