.edit-profile {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .edit-profile h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .edit-profile form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-profile form div {
    margin-bottom: 15px;
  }
  
  .edit-profile form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .edit-profile form input,
  .edit-profile form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .edit-profile form button {
    padding: 10px;
    background-color: #7C4DFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-profile form button:hover {
    background-color: #5a34b1;
  }
  