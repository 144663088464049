/******************************************************
 * Container & Content
 ******************************************************/
.adminDashboardContainer {
  display: flex;
  margin-bottom: 40px;
  padding-left: 60px;
}

.adminDashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  position: relative;
}

/******************************************************
 * Header
 ******************************************************/
.adminDashboardHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e3e4e8;
  margin: 0 -2rem 1rem;
  padding: 20px 2rem 10px 2rem;
  align-items: flex-start;
}

/******************************************************
 * Workflow Actions (Create Button)
 ******************************************************/
.workflowActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.createWorkflowBtn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #6d00cc;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid #6d00cc;
  cursor: pointer;
  transition: background 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.plusIcon {
  font-size: 18px;
}

.createWorkflowBtn:hover {
  background: #fff;
  color: #6d00cc;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/******************************************************
 * Workflow Grid 
 ******************************************************/
.adminWorkflowGrid {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  position: relative;
  margin-top: 10px;
  box-sizing: border-box;
  zoom: 0.7;
}

/******************************************************
 * Loading State
 ******************************************************/
.loadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingSpinner {
  width: 35px;
  height: 35px;
  border: 5px solid #eee;
  border-top: 5px solid #663399;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loadingMessage {
  color: #555;
  font-size: 1rem;
}

.rtl{
  direction: rtl;
}

.adminDashboardContainer.rtl {
  padding-right: 60px;
  padding-left: 0;
}

