html,
body {
    display: list-item;
    background: #f8f8f8;
    font-family: 'Arial', sans-serif;
    margin: 0px;
}

:root[lang="ar"] {
    direction: rtl !important;
}

